import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./descriptionPdf.css";
import AXIOS from "../../services/axios";

function DescriptionPdf() {
  const [descriptions, setDescriptions] = useState({
    table: [],
    color: "",
  });

  const handleGetTableData = async (version) => {
    try {
      let response;
      response = await AXIOS.get(`/user-template/description/KI5473S459/v2`);

      if (response && response.status === 200) {
        setDescriptions({
          ...descriptions,
          table: response.data.descriptions,
          color: response.data.color,
        });
      }
    } catch (error) {
      console.log("Error saving data", error);
    }
  };

  useEffect(() => {
    handleGetTableData();
  }, []);

  return (
    <div className="user-description-container" id="user-description">
      <div>
      <div className="user-description-header ">
        <div className="description-circle-container">
          {[...Array(20)].map((_, i) => (
            <span key={i} className="description-circle"></span>
          ))}
        </div>
        <div className="user-description-header-content">
          <h1>Brand Name</h1>
          <div className="user-description-logo">
            <img
              src="https://static.vecteezy.com/system/resources/thumbnails/027/254/720/small/colorful-ink-splash-on-transparent-background-png.png"
              alt=""
            />
          </div>
        </div>
      </div>

      
      </div>

      <div>
        hii
      </div>
    </div>
  );
}

export default DescriptionPdf;
