import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import A1 from "../../assets/profile_avatars/A1.png"
import A2 from "../../assets/profile_avatars/A2.png";
import A3 from "../../assets/profile_avatars/A3.png";
import A4 from "../../assets/profile_avatars/A4.png";
import A5 from "../../assets/profile_avatars/A5.png";
import A6 from "../../assets/profile_avatars/A6.png";
import { IoMdClose, IoIosCheckmarkCircleOutline } from "react-icons/io";
import logo from "../../assets/interioverse_logo.png";
import { IoMenu } from "react-icons/io5";
import Signup from "../signupPage/signupPage";
import Login from "../login/login";
import { useNavigate, useLocation } from "react-router-dom";
import AXIOS from "../../services/axios";
import Profile_card from "../profile_card/profile_card";
import { resendOtpTimer } from "../../services/reusableCodes";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "../../services/redux/thunk/authThunk";
import { setSignup } from "../../services/redux/actions/refreshToken.action";

function Header() {
  const [dropDown, setDropDown] = useState({
    menu: false,
    signUp: false,
    login: false,
    otp: false,
    mobileInput: true,
    verified: false,
    profile: false,
  }); //to show popup respective to state variable value
  const [otpInputs, setOtpInputs] = useState(Array(6).fill("")); // OTP input values
  const [verificationError, setVerificationError] = useState(""); // Error handling
  const [signupData, setSignupData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [loginData, setLoginData] = useState({
    phoneNumber: "",
    otp: "",
  });
  const [footerNav, setFooterNav] = useState("false"); //to show footer list in header
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [phoneError, setPhoneError] = useState({
    valid: false,
    exist: false,
  }); //state to check phone number is already exist or not
  const [timer, setTimer] = useState(30);
  const dropdownRef = useRef();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const adminRole = useSelector((state) => state.auth.adminRole);
  const role = useSelector((state) => state.auth.role);
  const profileImg = useSelector((state) => state.auth.profileImg);
  const isOpen = useSelector((state) => state.signup.isOpen);
  const isLogin = useSelector((state) => state.signup.isLogin);
  const dispatch = useDispatch();
  const clearTimerRef = useRef(null);

  const imageMap = {
    A1: A1,
    A2: A2,
    A3: A3,
    A4: A4,
    A5: A5,
    A6: A6,
  };
  

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (!signupData.name && !signupData.email) {
        dispatch(setSignup(false));
      }

      setDropDown((prevDropDown) => {
        return {
          signUp: signupData.name || signupData.email ? true : false,
          login: loginData.phoneNumber || loginData.otp ? true : false,
          profile: false,
          menu: false,
        };
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [signupData, loginData, dropdownRef]);

  useEffect(() => {
    setVerificationError(false);
  }, [otpInputs]);

  //function to format phone number to +91 xxxxx87654
  function formatPhoneNumber(phone) {
    if (phone.length !== 10) {
      console.log("Phone number must be 10 digits long");
    }

    const countryCode = "+91";
    const lastFourDigits = phone.slice(-4);
    const maskedPart = "xxxxx";

    return `${countryCode} ${maskedPart}${lastFourDigits}`;
  }

  // Handle changes to OTP input fields
  const handleOtpChange = (e, index) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = e.target.value;
    setOtpInputs(newOtpInputs);

    // Move focus to the next input
    if (e.target.value && index < 5) {
      const nextInput = document.querySelector(
        `.otp-input:nth-child(${index + 2})`
      );
      if (nextInput) {
        nextInput.focus();
      }
    }

    // Move focus to the previous input if the current input is empty
    if (!e.target.value && index > 0) {
      const prevInput = document.querySelector(
        `.otp-input:nth-child(${index})`
      );
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  // Handle changes to phone input fields
  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    setSignupData({
      ...signupData,
      [name]: name === "phone" ? value.replace(/\D/g, "") : value,
    });
  };

  // to verify otp
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    const otp = otpInputs.join(""); // Combine all 6 OTP inputs

    try {
      const response = await AXIOS.post("/user/verify-otp", {
        phone_number: signupData.phone,
        otp,
      });

      if (response.status === 200) {
        // OTP verification successful
        setDropDown({ ...dropDown, otp: true, verified: true });
        setSignupData({
          name: "",
          email: "",
          phone: "",
        });
        setOtpInputs(Array(6).fill(""));
        setTimeout(() => {
          setDropDown((prev) => ({
            ...prev,
            menu: false,
            signUp: false,
            login: false,
            otp: false,
            mobileInput: true,
            verified: false,
            profile: false,
          }));
          dispatch(refreshToken());
          navigate("/");
        }, 5000);
      }
    } catch (error) {
      console.error(
        "Error verifying OTP:",
        error.response?.data || error.message
      );

      setVerificationError("Incorrect OTP. Please try again."); // Error message for the user
    }
  };

  // to close popup
  const closePopup = () => {
    if (clearTimerRef.current) clearTimerRef.current(); // Stop the timer
    setTimer(30);
    setDropDown({
      ...dropDown,
      menu: false,
      signUp: false,
      login: false,
      otp: false,
      mobileInput: true,
      verified: false,
      profile: false,
    });
    setSignupData({ name: "", email: "", phone: "" });
    setPhoneError(false);
    dispatch(setSignup(false));
  };

  //to make phone number false when entering phone number is valid or exist
  useEffect(() => {
    setPhoneError({ ...phoneError, exist: false, valid: false });
  }, [signupData.phone]);

  // to submit data to backend
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (signupData.phone.length < 10) {
        setPhoneError({ ...phoneError, valid: true });
        return;
      }
      const verifyResponse = await AXIOS.post("/user/verify-phone_number", {
        phone_number: signupData.phone,
      });

      if (verifyResponse.status === 200) {
        const signupResponse = await AXIOS.post("/user/signup", {
          name: signupData.name,
          email: signupData.email,
          phone_number: signupData.phone,
        });

        if (signupResponse.status === 200) {
          clearTimerRef.current = resendOtpTimer(setTimer, 30);
          setDropDown({
            ...dropDown,
            mobileInput: false,
          });
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setPhoneError({ ...phoneError, exist: true });
        }
      } else {
        console.error("Error during signup:", error.message || error);
      }
    }
  };

  // to footer list in header
  useEffect(() => {
    const footerNavPaths = [
      "/contact-us",
      "/privacy-policy",
      "/shipping-delivery",
      "/cancellation-refund",
      "/terms-conditions",
    ];

    setFooterNav(footerNavPaths.includes(pathname)); // Checks if the current path is in the list
  }, [pathname]); // Re-run effect every time `pathname` changes

  return (
    <header
      style={{
        backgroundColor:
          pathname !== "/" &&
          pathname !== "/new-project" &&
          pathname !== "/book-service" &&
          pathname !== "/payment" &&
          pathname !== "/be-a-partner"&&
          pathname !== "/unity" &&
          pathname !== "/unity/" &&
          pathname !== "/unity-create-project" 
            ? "#1f1f1f"
            : "",

      }}
    >
      {/* Popup to enter mobile number and otp verification */}
      {dropDown.otp && (
        <section className="otp-popup">
          <div className="otp-container">
            <IoMdClose className="otp-close" onClick={closePopup} />
            {dropDown.otp && !dropDown.verified && (
              <div className="otp-content">
                <h2 className="otp-title">
                  {dropDown.mobileInput
                    ? "Contact details"
                    : "OTP Verification"}
                </h2>

                {/* mobile number input section  */}
                {dropDown && dropDown.mobileInput && (
                  <div className="signup-form-group">
                    <p className="otp-instruction">
                      Please provide your mobile number to receive an OTP.{" "}
                    </p>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        id="phone"
                        placeholder="Enter Phone Number"
                        required
                        maxLength={10}
                        name="phone"
                        value={signupData.phone}
                        onChange={handlePhoneChange}
                        className={`form-input signup-phone-input ${
                          phoneError.exist || phoneError.valid
                            ? "input-error"
                            : ""
                        }`}
                      />
                      {(phoneError.exist || phoneError.valid) && (
                        <p className="error">
                          {phoneError.exist
                            ? "Mobile number already exist"
                            : "Mobile number must be 10 digits"}
                        </p>
                      )}
                      <button className="get-otp-button" type="submit">
                        Get OTP
                      </button>
                    </form>
                  </div>
                )}

                {/* otp input section  */}
                {!dropDown.mobileInput && signupData.phone && (
                  <div>
                    <p className="otp-instruction">
                      Enter the OTP code sent to the mobile number{" "}
                      <strong>
                        {signupData && `${formatPhoneNumber(signupData.phone)}`}
                      </strong>
                    </p>

                    <form onSubmit={handleVerifyOtp}>
                      <div className="otp-input-group">
                        {otpInputs.map((value, index) => (
                          <input
                            key={index}
                            type="text"
                            className="otp-input"
                            maxLength="1"
                            value={value}
                            onChange={(e) => handleOtpChange(e, index)}
                          />
                        ))}
                      </div>

                      {verificationError && (
                        <div className="error">{verificationError}</div>
                      )}

                      <div className="otp-resend">
                        {timer === 0 ? (
                          <>
                            Didn’t receive OTP?{" "}
                            <span onClick={timer === 0 ? handleSubmit : null}>
                              Resend OTP
                            </span>
                          </>
                        ) : (
                          <span>{timer} seconds</span>
                        )}
                      </div>

                      <button type="submit" className="otp-verify-button">
                        Verify
                      </button>
                    </form>
                  </div>
                )}
              </div>
            )}

            {/* verified section  */}
            {dropDown.verified && (
              <div className="otp-content">
                <div className="verified-icon-container">
                  <IoIosCheckmarkCircleOutline className="verified-icon" />
                </div>
                <h2 className="verified-success-heading">
                  Account created successfully
                </h2>
                <p className="verification-notice">
                  Designer’s account will be activated only after the
                  verification of your KYC documents and your designing
                  capabilities by Interioverse.
                </p>
                <p className="verification-processing-time">
                  It may take 2-4 working days
                </p>
              </div>
            )}
          </div>
        </section>
      )}

      {/* Header for larger screens */}
      <div className="header-container">
        <div className="header-for-laptop">
          <img
            src={logo}
            alt="Interioverse Logo"
            className="header-logo"
            onClick={() => navigate("/")}
          />

          {/* nav list for not loged in  */}
          {!isAuth && (
            <nav className="header-nav">
              <ul className="header-nav-list">
                <li className="header-nav-login">
                  <a
                    onClick={() => {
                      setDropDown({
                        ...dropDown,
                        signUp: false,
                        login: !dropDown.login,
                        profile: false,
                      });
                      setLoginData({
                        phoneNumber: "",
                        otp: "",
                      });
                    }}
                  >
                    Login
                  </a>
                </li>
                <li className="header-nav-signup">
                  <a
                    onClick={() => {
                      setDropDown({
                        ...dropDown,
                        signUp: !dropDown.signUp,
                        login: false,
                        profile: false,
                      });
                      setSignupData({
                        name: "",
                        email: "",
                        phone: "",
                      });
                    }}
                  >
                    Signup
                  </a>
                </li>
              </ul>
            </nav>
          )}

          {/* nav list for logedin  */}
          {isAuth && (
            <nav className="header-nav">
              <ul className="header-nav-list">
                {adminRole === "Designer" && pathname !== "/unity" && pathname !== "/unity-create-project" &&(
                  <li className="header-nav-service">
                    <a onClick={() => navigate("/book-service")}>
                      Book a Service
                    </a>
                  </li>
                )}
                {role === "user" && adminRole === "user" && pathname !== "/unity" &&pathname !== "/unity-create-project" && (
                  <li className="header-nav-partner">
                    <a onClick={() => navigate("/be-a-partner")}>
                      Be a Partner
                    </a>
                  </li>
                )}
                {adminRole !== "user" && pathname !== "/unity" &&pathname !== "/unity-create-project" &&(
                  <li
                    className="header-nav-partner"
                    style={{ cursor: "default" }}
                  >
                    <a>{adminRole}</a>
                  </li>
                )}
                {role !== "user" && adminRole === "user" && pathname !== "/unity" && pathname !== "/unity-create-project" && (
                  <li
                    className="header-nav-partner"
                    style={{ cursor: "default" }}
                  >
                    <a>In Process</a>
                  </li>
                )}
                <li
                  className="header-nav-signup"
                  onClick={() =>
                    setDropDown({
                      ...dropDown,
                      signUp: false,
                      login: false,
                      profile: !dropDown.profile,
                    })
                  }
                >
                  <img
                    src={imageMap[profileImg] || A1}
                    alt="profile-image"
                    className="header-profile-image "
                  />
                </li>
              </ul>
            </nav>
          )}
        </div>

        {/* using footer css  */}
        {footerNav && (
          <nav aria-label="Footer Navigation">
            <ul className="footer-nav-list footer-nav-list-in-header">
              <li
                className={`footer-nav-item-in-header ${
                  pathname == "/contact-us" ? "active-nav-item-in-header" : ""
                }`}
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </li>
              <li
                className={`footer-nav-item-in-header ${
                  pathname == "/privacy-policy"
                    ? "active-nav-item-in-header"
                    : ""
                }`}
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </li>
              <li
                className={`footer-nav-item-in-header ${
                  pathname == "/shipping-delivery"
                    ? "active-nav-item-in-header"
                    : ""
                }`}
                onClick={() => navigate("/shipping-delivery")}
              >
                Shipping and Delivery
              </li>
              <li
                className={`footer-nav-item-in-header ${
                  pathname == "/cancellation-refund"
                    ? "active-nav-item-in-header"
                    : ""
                }`}
                onClick={() => navigate("/cancellation-refund")}
              >
                Cancellation and Refund
              </li>
              <li
                className={`footer-nav-item-in-header ${
                  pathname == "/terms-conditions"
                    ? "active-nav-item-in-header"
                    : ""
                }`}
                onClick={() => navigate("/terms-conditions")}
              >
                Terms and Conditions
              </li>
            </ul>
          </nav>
        )}

        {/* Signup and login popups */}
        {(dropDown.signUp ||
          dropDown.login ||
          dropDown.profile ||
          isOpen ||
          isLogin) && (
          <article className="user-access-drop-down" ref={dropdownRef}>
            {(dropDown.signUp || isOpen) &&
              !dropDown.login &&
              !dropDown.profile && (
                <Signup
                  setDropDown={setDropDown}
                  dropDown={dropDown}
                  signupData={signupData}
                  setSignupData={setSignupData}
                />
              )}
            {(dropDown.login || isLogin) &&
              !dropDown.signUp &&
              !dropDown.profile && (
                <Login
                  setDropDown={setDropDown}
                  dropDown={dropDown}
                  loginData={loginData}
                  setLoginData={setLoginData}
                />
              )}

            {!dropDown.signUp && !dropDown.login && dropDown.profile && (
              <Profile_card setDropDown={setDropDown} dropDown={dropDown} />
            )}
          </article>
        )}
      </div>

      {/* Header for mobile screens*/}
      <div className="header-for-mobile">
        <div>
          <IoMenu
            className="header-menu-icon"
            onClick={() =>
              setDropDown({ ...dropDown, menu: !dropDown.menu, signUp: false })
            }
            style={{ visibility: adminRole !== "Designer" ? "hidden" : "" }}
          />
          <img
            src={logo}
            alt="Interioverse Logo"
            className="header-logo"
            onClick={() => navigate("/")}
          />
          <img
            src={imageMap[profileImg] || A1}
            alt="profile-image"
            className="header-mobile-profile-image"
            onClick={() =>
              setDropDown({
                ...dropDown,
                signUp: false,
                login: false,
                profile: !dropDown.profile,
              })
            }
            style={{ visibility: !isAuth ? "hidden" : "" }}
          />
        </div>

        {!isAuth && (
          <nav
            className={`header-mobile-nav ${dropDown.menu ? "open" : ""}`}
            ref={dropdownRef}
          >
            <ul className="header-mobile-nav-list">
              <li className="header-mobile-nav-signup">
                <a
                  onClick={() => {
                    setDropDown({
                      ...dropDown,
                      menu: false,
                      signUp: false,
                      login: !dropDown.login,
                      profile: false,
                    });
                    setLoginData({
                      phoneNumber: "",
                      otp: "",
                    });
                  }}
                >
                  Login
                </a>
              </li>
              <li className="header-mobile-nav-signup">
                <a
                  onClick={() => {
                    setDropDown({
                      ...dropDown,
                      menu: false,
                      signUp: !dropDown.signUp,
                      login: false,
                      profile: false,
                    });
                    setSignupData({
                      name: "",
                      email: "",
                      phone: "",
                    });
                  }}
                >
                  Signup
                </a>
              </li>
            </ul>
          </nav>
        )}

        {isAuth && (
          <nav
            className={`header-mobile-nav ${dropDown.menu ? "open" : ""}`}
            ref={dropdownRef}
          >
            <ul className="header-mobile-nav-list">
              {adminRole === "Designer" && (
                <li className="header-mobile-nav-signup">
                  <a
                    onClick={() => {
                      navigate("/book-service");
                      setDropDown({
                        ...dropDown,
                        menu: false,
                        profile: false,
                      });
                    }}
                  >
                    Book a Service
                  </a>
                </li>
              )}
              {role === "user" && adminRole === "user" &&  (
                <li className="header-mobile-nav-signup">
                  <a
                    onClick={() => {
                      navigate("/be-a-partner");
                      setDropDown({
                        ...dropDown,
                        menu: false,
                        profile: false,
                      });
                    }}
                  >
                    Be a Partner
                  </a>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Header;
