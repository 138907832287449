import React, { useEffect, useState } from "react";
import "./edit-project-popup.css";
import CustomRadioButton from "../../components/radioBtn/radioBtn";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AXIOS from "../../services/axios";

function EditProjectPopup({ editProject, setEditProject ,projectsData}) {
  const [form, setForm] = useState({
    project_name: "",
    project_layout: "",
    project_location: "",
    property_name: "",
    builder_name: "",
  });


  useEffect(() => {
    const fetchCities = async () => {
      try {
        const projectResponse = await AXIOS.get(
          `/project/get-project-by-id/${editProject}`
        );
        if (projectResponse.status === 200) {
          const { project } = projectResponse.data;

          setForm((prev) => ({
            ...prev,
            project_name: project.project_name,
            project_layout: project.project_layout,
            project_location: project.project_location,
            property_name: project.property_name,
            builder_name: project.builder_name,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCities();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await AXIOS.put(`/project/update-project/${editProject}`, form);
      if (response.status === 200) {
        projectsData()
            setEditProject('')

      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <section style={{ margin: "0px", padding: "0" }}>
      <div className="edit-project">
        <div className="new-project-container edit-project-container ">
          <IoMdClose
            className="new-project-close-icon"
            onClick={() => setEditProject("")}
          />
          <div className="new-project-content">
            <h1 className="new-project-heading">Edit Project</h1>
            <form onSubmit={handleSubmit}>
              <div className="project-form">
                <div className="project-form-section">
                  <div className="project-form-group">
                    <label
                      className="project-form-label"
                      htmlFor="project-name"
                    >
                      Project Name
                    </label>
                    <input
                      id="project-name"
                      type="text"
                      className="project-form-input"
                      placeholder="Name your project"
                      required
                      name="project_name"
                      value={form.project_name}
                      onChange={handleChange}
                      spellCheck={false}
                    />
                  </div>

                  <div className="project-form-group" style={{opacity:0.65}}>
                    <label
                      className="project-form-label"
                      htmlFor="project-layout"
                    >
                      Project Layout
                    </label>
                    <input
                      id="project-layout"
                      type="text"
                      className="project-form-input"
                      placeholder="Select Your Layout"
                      value={form.project_layout}
                      name="project_layout"
                      readOnly
                      required
                    />
                  
                  </div>
                  <div className="project-form-group" style={{opacity:0.65}}>
                      <label
                        className="project-form-label"
                        htmlFor="project-location"
                      >
                        Project Location
                      </label>
                      <input
                        id="project-location"
                        type="text"
                        className="project-form-input"
                        placeholder="Enter your project location"
                        required
                        name="project_location"
                        value={form.project_location}
                        autoComplete="off"
                        readOnly
                      />
                    </div>
                </div>

                <div className="project-form-section" >
                  <div className="project-form-group" style={{opacity:0.65}}>
                    <label
                      className="project-form-label"
                      htmlFor="property-name"
                    >
                      Name of Property
                    </label>
                    <input
                      id="property-name"
                      type="text"
                      className="project-form-input"
                      placeholder="Enter your property name"
                      required
                      name="property_name"
                      value={form.property_name}
                      readOnly
                    />
                  </div>

                  <div className="project-form-group" style={{opacity:0.65}}>
                    <label
                      className="project-form-label"
                      htmlFor="builder-name"
                    >
                      Name of Builder
                    </label>
                    <input
                      id="builder-name"
                      type="text"
                      className="project-form-input"
                      placeholder="Enter your builder name"
                      required
                      name="builder_name"
                      value={form.builder_name}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="create-project-btn">
                <button type="submit">Update Project</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditProjectPopup;
