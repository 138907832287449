// src/redux/reducers/authReducer.js
import {
  SET_AUTH,
  SET_USER_ROLE,
  SET_ADMIN_ROLE,
  SET_DESIGNER_COUNT,
  SET_PROFILE_IMG,
  SET_REFFERED_BY_NAMES,
  SET_REFFERAL_DAYS,
} from "../actions/refreshToken.action";

const initialState = {
  isAuthenticated: false,
  role: null,
  adminRole: null,
  profileImg: "",
  count: null,
  names : [],
  days : null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_USER_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case SET_ADMIN_ROLE:
      return {
        ...state,
        adminRole: action.payload,
      };
    case SET_PROFILE_IMG:
      return {
        ...state,
        profileImg: action.payload,
      };
    case SET_DESIGNER_COUNT:
      return {
        ...state,
        count: action.payload,
      };
      case SET_REFFERED_BY_NAMES:
      return {
        ...state,
        names: action.payload,
      };
      case SET_REFFERAL_DAYS:
      return {
        ...state,
        days: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
