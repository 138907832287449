import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import siteMeasurementPic from "../../assets/SiteMeasurement.png";
import tracktor from "../../assets/tracktor.jpg";
import walkthrough from "../../assets/walkthrough.jpg";
import cleaning from "../../assets/cleaning.jpg";
import floor from "../../assets/floor.jpg";
import "./servicesPopup.css";
import { useNavigate } from "react-router-dom";
import { formatIndianNumber } from "../../services/reusableCodes";
import { IoCheckmarkCircle } from "react-icons/io5";
import AXIOS from "../../services/axios";
import logo from "../../assets/logo.png";

// Sample data for the table

const serviceData = [
  { site: "Studio or 1 BHK", cadOnly: 3500, cadSketchup: 4650 },
  { site: "2 BHK", cadOnly: 3770, cadSketchup: 4950 },
  { site: "3 BHK", cadOnly: 4290, cadSketchup: 5850 },
  { site: "4 & 5 BHK", cadOnly: 6500, cadSketchup: 8450 },
  { site: "G+1 Villa", cadOnly: 8450, cadSketchup: 11700 },
  { site: "G+2 Villa", cadOnly: 10400, cadSketchup: 14950 },
];

const deepData = [
  { site: "Studio or 1 BHK (up to 1000 sq. ft)", rate: 5800 },
  { site: "2 BHK (up to 1200 sq. ft)", rate: 7250 },
  { site: "3 BHK (up to 1500 sq. ft)", rate: 8250 },
  {
    site: "4 BHK/Villa or Larger Property",
    rate: "6.5 per sq.ft (Built-up area)",
  },
  { site: "Commercial", rate: "6.5 per sq.ft (Built-up area)" },
];

const floorItems = ["3D Renders", "Walkthrough", "360° Virtual Tour"];

const floorData = ["Sketchup Model & CAD Drawings", "CAD Drawing Only"];

function ServicesPopup({ setIsServicePopup, isServicePopup, cities }) {
  const navigate = useNavigate();
  const [siteService, setSiteServices] = useState({
    site: serviceData[0].site,
    service: "CAD File Only",
    price: serviceData[0].cadOnly,
    location: "Bengaluru",
    selectedCell: { site: serviceData[0].site, service: "CAD File Only" },
    error: false,
  });
  const [debrisService, setDebrisServices] = useState({
    load: "",
    price: 0,
    location: "Bengaluru",
    error: false,
  });
  const [floorService, setFloorServices] = useState({
    sft: "",
    price: 0,
    location: "Bengaluru",
    error: false,
  });
  const [deepService, setDeepServices] = useState({
    site: deepData[0].site,
    price: deepData[0].rate,
    location: "Bengaluru",
    selectedCell: { site: deepData[0].site },
    area: "",
    error: false,
  });
  const [renderService, setRenderServices] = useState({
    userProvide: [],
    floor: "Sketchup Model & CAD Drawings",
    sec: "",
    sft: "",
    points: "",
    price: 0,
    location: "Bengaluru",
    error: false,
  });

  const [paymentInput, setPaymentInput] = useState({
    name: "",
    phone: "",
    gst: "",
    billing: "",
    coupon: "",
    price: "",
  });
  const [paymentPopup, setPaymentPopup] = useState("");

  const handleTableClick = (site, service, price) => {
    setSiteServices({
      site,
      service,
      price,
      selectedCell: { site, service },
      error: false,
    });
  };

  const handleDeepClick = (site, price) => {
    setDeepServices({
      site,
      price,
      selectedCell: { site },
      error: false,
    });
  };

  const handleSelectChange = (event) => {
    setSiteServices({
      ...siteService,
      location: event.target.value,
      error: false,
    });
  };

  const handleDebrisChange = (e) => {
    const { name, value } = e.target;

    setDebrisServices({
      ...debrisService,
      [name]: value,
      error: false,
    });

    if (name === "load") {
      setDebrisServices({
        ...debrisService,
        [name]: value.replace(/\D/g, ""),
        price: Math.ceil(parseFloat(value * 3600)),
        error: false,
      });
    }
  };

  const handleDeepChange = (event) => {
    const { name, value } = event.target;
    setDeepServices({
      ...deepService,
      [name]: value,
      error: false,
    });

    if (name === "area") {
      setDeepServices({
        ...deepService,
        [name]: value.replace(/\D/g, ""),
        price: Math.ceil(parseFloat(value * 6.5)),
        error: false,
      });
    }
  };

  const handleFloorChange = (e) => {
    const { name, value } = e.target;
    setFloorServices({
      ...floorService,
      [name]: value,
      error: false,
    });

    if (name === "sft") {
      setFloorServices({
        ...floorService,
        [name]: value.replace(/\D/g, ""),
        price: Math.ceil(parseFloat(value * 10)),
        error: false,
      });
    }
  };

  const handleRenderChange = (e) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value.replace(/\D/g, "")) || 0; // Strip non-numeric characters

    setRenderServices((prevService) => {
      // Base price depending on the 'floor' value
      const baseSecPrice =
        prevService.floor === "Sketchup Model & CAD Drawings" ? 280 : 680;
      const baseSftPrice =
        prevService.floor === "Sketchup Model & CAD Drawings" ? 5 : 28;
      const basePointsPrice =
        prevService.floor === "Sketchup Model & CAD Drawings" ? 5250 : 6550;

      // Recalculate price based on all fields
      const updatedSecPrice = parseFloat(prevService.sec || 0) * baseSecPrice;
      const updatedSftPrice = parseFloat(prevService.sft || 0) * baseSftPrice;
      const updatedPointsPrice =
        parseFloat(prevService.points || 0) * basePointsPrice;

      let newPrice = updatedSecPrice + updatedSftPrice + updatedPointsPrice;

      // Adjust current field change (if sec, sft, or points is updated)
      if (name === "sec") {
        newPrice =
          numericValue * baseSecPrice + updatedSftPrice + updatedPointsPrice;
      } else if (name === "sft") {
        newPrice =
          updatedSecPrice + numericValue * baseSftPrice + updatedPointsPrice;
      } else if (name === "points") {
        newPrice =
          updatedSecPrice + updatedSftPrice + numericValue * basePointsPrice;
      }

      return {
        ...prevService,
        [name]: numericValue, // Update the current field
        price: newPrice, // Update the price dynamically
        error: false,
      };
    });
  };

  const handleRenderItemClick = (name) => {
    setRenderServices((prevState) => {
      const isAlreadySelected = prevState.userProvide.includes(name);

      // Base prices depending on the 'floor' value
      const baseSecPrice =
        prevState.floor === "Sketchup Model & CAD Drawings" ? 280 : 680;
      const baseSftPrice =
        prevState.floor === "Sketchup Model & CAD Drawings" ? 5 : 28;
      const basePointsPrice =
        prevState.floor === "Sketchup Model & CAD Drawings" ? 5250 : 6550;

      // Current calculated prices for selected sec, sft, and points
      const currentSecPrice = parseFloat(prevState.sec || 0) * baseSecPrice;
      const currentSftPrice = parseFloat(prevState.sft || 0) * baseSftPrice;
      const currentPointsPrice =
        parseFloat(prevState.points || 0) * basePointsPrice;

      let updatedPrice = currentSecPrice + currentSftPrice + currentPointsPrice;

      // Handle deselect (subtract corresponding price)
      if (name === "3D Renders" && isAlreadySelected) {
        updatedPrice -= currentSftPrice; // Deselect 3D Renders affects 'sft'
      } else if (name === "Walkthrough" && isAlreadySelected) {
        updatedPrice -= currentSecPrice; // Deselect Walkthrough affects 'sec'
      } else if (name === "360° Virtual Tour" && isAlreadySelected) {
        updatedPrice -= currentPointsPrice; // Deselect Virtual Tour affects 'points'
      }

      let stateKey;

      if (name === "3D Renders") {
        stateKey = "sft";
      } else if (name === "Walkthrough") {
        stateKey = "sec";
      } else if (name === "360° Virtual Tour") {
        stateKey = "points";
      }

      // Update the state with the selected or deselected item and adjust the price
      return {
        ...prevState,
        userProvide: isAlreadySelected
          ? prevState.userProvide.filter((item) => item !== name)
          : [...prevState.userProvide, name],
        [stateKey]: "",
        price: updatedPrice, // Update the price dynamically
        error: false,
      };
    });
  };

  const handleSiteSubmit = async (e) => {
    e.preventDefault();
    try {
      const serviceType =
        siteService.service === "CAD File Only" ? "cadOnly" : "cadSketchup";

      const response = await AXIOS.post("/book-services/site-measurement", {
        site: siteService.site,
        serviceType: serviceType,
      });

      if (response.status === 200) {
        setSiteServices({
          ...siteService,
          price: response.data.amount,
        });
        setPaymentPopup(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDebrisSubmit = async (e) => {
    e.preventDefault();
    if (!debrisService.load) {
      setDebrisServices({
        ...debrisService,
        error: true,
      });
      return;
    }
    try {
      const response = await AXIOS.post("/book-services/debris-removal", {
        load: debrisService.load,
      });

      if (response.status === 200) {
        setDebrisServices({
          ...debrisService,
          price: response.data.amount,
        });
        setPaymentPopup(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeepSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await AXIOS.post("/book-services/deep-cleaning", {
        site: deepService.site,
        area: deepService.area,
      });

      if (response.status === 200) {
        setDeepServices({
          ...deepService,
          price: response.data.amount,
        });
        setPaymentPopup(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFloorSubmit = async (e) => {
    e.preventDefault();
    if (!floorService.sft) {
      setFloorServices({
        ...floorService,
        error: true,
      });
      return;
    }
    try {
      const response = await AXIOS.post("/book-services/floor-protection", {
        floor_area: floorService.sft,
      });

      if (response.status === 200) {
        setFloorServices({
          ...floorService,
          price: response.data.amount,
        });
        setPaymentPopup(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRenderSubmit = async (e) => {
    e.preventDefault();
    if (!renderService.sft && !renderService.sec && !renderService.points) {
      setRenderServices({
        ...renderService,
        error: true,
      });
      return;
    }
    try {
      const response = await AXIOS.post("/book-services/rendering-service", {
        serviceType: renderService.floor,
        area: renderService.sft,
        video: renderService.sec,
        view_point: renderService.points,
      });

      if (response.status === 200) {
        setRenderServices({
          ...renderService,
          price: response.data.amount,
        });
        setPaymentPopup(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPaymentInput({
      ...paymentInput,
      [name]: value,
      price: "",
    });
  };

  const getServiceState = () => {
    switch (isServicePopup) {
      case "site":
        return siteService;
      case "debris":
        return debrisService;
      case "deep":
        return deepService;
      case "floor":
        return floorService;
      case "render":
        return renderService;
      default:
        return null;
    }
  };

  const handleCoupon = async (e) => {
    e.preventDefault();
    try {
      const currentServiceState = getServiceState();

      if (!currentServiceState) {
        console.log("Invalid service selected");
        return;
      }

      // Create the coupon object
      const Coupon = {
        amount: currentServiceState.price, // Get the price from the current service state
        couponCode: paymentInput.coupon, // Replace with actual coupon code input if needed
      };

      const response = await AXIOS.post("/book-services/apply-coupon-code", {
        amount: Coupon.amount,
        couponCode: Coupon.couponCode,
      });

      if (response.status === 200) {
        setPaymentInput({
          ...paymentInput,
          price: response.data.finalAmount, // Update the price with the discounted price
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initPayment = (data, amount) => {
    const options = {
      key: process.env.REACT_APP_RAZOR_KEY, // Replace with your live Razorpay API key
      amount: data.amount,
      currency: data.currency,
      name: "Interioverse Design Pvt. Ltd.", // Update with your subscription plan name
      description: "Service Payment",
      image: logo,
      order_id: data.id,
      handler: async (response) => {
        try {
          const verifyUrl = "/payment/verify"; // Adjust endpoint as per your backend configuration
          const { data, status } = await AXIOS.post(verifyUrl, response);
          if (status === 200) {
            const response = await AXIOS.post(
              "/book-services/payment-success",
              {
                name: paymentInput.name,
                phone_number: paymentInput.phone,
                gstin: paymentInput.gst,
                address: paymentInput.billing,
                service: isServicePopup,
                amount: amount,
              }
            );
            setPaymentPopup(false);
            setIsServicePopup("");
          }
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async (e) => {
    e.preventDefault();

    const currentServiceState = getServiceState();

    const amount = paymentInput.price
      ? parseFloat(paymentInput.price + Math.ceil(paymentInput.price * 0.18))
      : currentServiceState.price + Math.ceil(currentServiceState.price * 0.18);
    try {
      const orderUrl = "/payment/order"; // Adjust endpoint as per your backend configuration
      const { data } = await AXIOS.post(orderUrl, { amount: amount * 10 }); // Amount in INR
      initPayment(data.data, amount);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section style={{ margin: "0px", padding: "0" }}>
      <div className="edit-project">
        <div className="new-project-container edit-project-container ">
          <IoMdClose
            className="new-project-close-icon"
            onClick={() => {
              if (paymentPopup) {
                setPaymentPopup(false);
                setPaymentInput({
                  name: "",
                  phone: "",
                  gst: "",
                  billing: "",
                  coupon: "",
                  price: "",
                });
              } else {
                setIsServicePopup("");
              }
            }}
          />
          <div className="new-project-content">
            {isServicePopup === "site" && (
              <h1 className="service-heading">Site Measurement</h1>
            )}
            {isServicePopup === "debris" && (
              <h1 className="service-heading">Debris Removal</h1>
            )}
            {isServicePopup === "deep" && (
              <h1 className="service-heading">Deep Cleaning</h1>
            )}
            {isServicePopup === "floor" && (
              <h1 className="service-heading">Floor Protection</h1>
            )}
            {isServicePopup === "render" && (
              <h1 className="service-heading">
                3D Rendering Services & Walkthrough
              </h1>
            )}

            {cities && (
              <div className="service-cities-list">
                {cities && cities.map((city) => city.toUpperCase()).join(" | ")}
              </div>
            )}

            {!paymentPopup && (
              <div className="service-container">
                <div className="service-map">
                  {isServicePopup === "site" && (
                    <img
                      src={siteMeasurementPic}
                      alt="map"
                      className="service-map-image"
                    />
                  )}
                  {isServicePopup === "deep" && (
                    <img
                      src={cleaning}
                      alt="map"
                      className="service-map-image"
                    />
                  )}
                  {isServicePopup === "debris" && (
                    <img
                      src={tracktor}
                      alt="map"
                      className="service-map-image"
                    />
                  )}
                  {isServicePopup === "floor" && (
                    <img src={floor} alt="map" className="service-map-image" />
                  )}
                  {isServicePopup === "render" && (
                    <img
                      src={walkthrough}
                      alt="map"
                      className="service-map-image"
                    />
                  )}
                </div>

                {isServicePopup === "site" && (
                  <div className="service-details">
                    <div className="service-title">Service Rates</div>

                    <table className="service-table">
                      <thead>
                        <tr className="service-header">
                          <th className="service-header-cell">Site</th>
                          <th className="service-header-cell">CAD File Only</th>
                          <th className="service-header-cell">
                            CAD & Sketchup 3D
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceData.map((service, index) => (
                          <tr key={index} className="service-row">
                            <td className="service-cell">{service.site}</td>
                            <td
                              className={`service-cell price-cell ${
                                siteService.selectedCell?.site ===
                                  service.site &&
                                siteService.selectedCell?.service ===
                                  "CAD File Only"
                                  ? "selected-price-cell"
                                  : ""
                              }`}
                              onClick={() =>
                                handleTableClick(
                                  service.site,
                                  "CAD File Only",
                                  service.cadOnly
                                )
                              }
                            >
                              <span>
                                {"₹ " + formatIndianNumber(service.cadOnly)}
                              </span>
                              {siteService.selectedCell?.site ===
                                service.site &&
                                siteService.selectedCell?.service ===
                                  "CAD File Only" && (
                                  <IoCheckmarkCircle className="selected-service-icon" />
                                )}
                            </td>
                            <td
                              className={`service-cell price-cell ${
                                siteService.selectedCell?.site ===
                                  service.site &&
                                siteService.selectedCell?.service ===
                                  "CAD & Sketchup 3D"
                                  ? "selected-price-cell"
                                  : ""
                              }`}
                              onClick={() =>
                                handleTableClick(
                                  service.site,
                                  "CAD & Sketchup 3D",
                                  service.cadSketchup
                                )
                              }
                            >
                              <span>
                                {"₹ " + formatIndianNumber(service.cadSketchup)}
                              </span>
                              {siteService.selectedCell?.site ===
                                service.site &&
                                siteService.selectedCell?.service ===
                                  "CAD & Sketchup 3D" && (
                                  <IoCheckmarkCircle className="selected-service-icon" />
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="service-selector">
                      <div className="site-city-selection-container">
                        <label
                          htmlFor="site-city-select"
                          className="site-city-select-label"
                        >
                          Select your City
                        </label>
                        <select
                          className="landing-page-city-name site-city-select-dropdown"
                          name="location"
                          value={siteService.location}
                          onChange={handleSelectChange}
                        >
                          <option value="" disabled selected>
                            Select City
                          </option>
                          {cities.length > 0 &&
                            cities.map((city, index) => (
                              <option key={index} value={city}>
                                {city}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="site-service-description-container">
                        <p className="site-service-description">
                          {siteService.site ? (
                            <>
                              {`Selected - ${siteService.site} - ${siteService.service} - `}
                              <span style={{ fontWeight: "700" }}>
                                ₹ {formatIndianNumber(siteService.price)}
                              </span>
                            </>
                          ) : (
                            "Please select the service"
                          )}
                        </p>

                        <button
                          className="service-sending-button"
                          onClick={handleSiteSubmit}
                        >
                          Book
                        </button>
                        {siteService.error && (
                          <p className="error error-site">
                            {siteService.error === "location"
                              ? "Please select location"
                              : "Please select service"}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isServicePopup === "debris" && (
                  <div className="service-details">
                    <div className="service-title">Debris Removal</div>
                    <div className="debris-details-content">
                      <div className="debris-input-group">
                        <div className="debris-input-item">
                          <label
                            htmlFor="Truck-Load"
                            className="site-city-select-label"
                          >
                            Truck-Load Required (Tractor Trolly)
                          </label>
                          <div className="debris-input-field">
                            <input
                              type="text"
                              className="debris-load-input"
                              placeholder="Enter total loads"
                              value={debrisService.load}
                              name="load"
                              onChange={handleDebrisChange}
                            />
                            <span className="debris-load-unit">Load</span>
                          </div>
                        </div>
                        <div className="debris-rate-group">
                          <label
                            htmlFor="rate"
                            className="site-city-select-label"
                          >
                            Rate <span>(excl. of GST)</span>
                          </label>
                          <button className="debris-rate-button">
                            {debrisService.price
                              ? `₹ ${formatIndianNumber(debrisService.price)}`
                              : "-"}
                          </button>
                        </div>
                      </div>
                      <div className="debris-selection-group">
                        <div className="debris-input-item">
                          <label
                            htmlFor="site-city-select"
                            className="site-city-select-label"
                          >
                            Select your City
                          </label>
                          <select
                            className="landing-page-city-name site-city-select-dropdown"
                            name="location"
                            value={debrisService.location}
                            onChange={handleDebrisChange}
                          >
                            <option value="" disabled selected>
                              Select City
                            </option>
                            {cities.length > 0 &&
                              cities.map((city, index) => (
                                <option key={index} value={city}>
                                  {city}
                                </option>
                              ))}
                          </select>

                          {debrisService.price ? (
                            <p className="debris-selection-summary">
                              Selected <span>{debrisService.load || 1}</span>{" "}
                              load floor protection -{" "}
                              <span>{` ₹ ${formatIndianNumber(
                                debrisService.price
                              )}`}</span>
                            </p>
                          ) : (
                            <p
                              className="debris-selection-summary"
                              style={{
                                color: debrisService.error ? "red" : "initial",
                              }}
                            >
                              Please enter load
                            </p>
                          )}
                        </div>
                        <div className="debris-booking-button-container">
                          <button
                            className="service-booking-button"
                            onClick={handleDebrisSubmit}
                          >
                            Book
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isServicePopup === "deep" && (
                  <div className="service-details">
                    <div className="service-title">Deep Cleaning</div>

                    <table className="service-table">
                      <thead>
                        <tr className="service-header">
                          <th className="service-header-cell">Site</th>
                          <th className="service-header-cell">Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deepData.map((service, index) => (
                          <tr key={index} className="service-row">
                            <td className="service-cell">{service.site}</td>
                            <td
                              className={`service-cell price-cell ${
                                deepService.selectedCell?.site === service.site
                                  ? "selected-price-cell"
                                  : ""
                              }`}
                              onClick={() =>
                                handleDeepClick(service.site, service.rate)
                              }
                            >
                              <span>
                                {service.rate !==
                                "6.5 per sq.ft (Built-up area)"
                                  ? "₹ " + formatIndianNumber(service.rate)
                                  : "₹ " + service.rate}
                              </span>
                              {deepService.selectedCell?.site ===
                                service.site && (
                                <IoCheckmarkCircle className="selected-service-icon" />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="service-selector deep-service-selector">
                      <div className="site-city-selection-container deep-cleaning-selection-container">
                        <label
                          htmlFor="site-city-select"
                          className="site-city-select-label"
                        >
                          Select your City
                        </label>
                        <select
                          className="landing-page-city-name site-city-select-dropdown "
                          name="location"
                          value={deepService.location}
                          onChange={handleDeepChange}
                        >
                          <option value="" disabled selected>
                            Select City
                          </option>
                          {cities.length > 0 &&
                            cities.map((city, index) => (
                              <option key={index} value={city}>
                                {city}
                              </option>
                            ))}
                        </select>
                      </div>
                      {(deepService.selectedCell.site ===
                        "4 BHK/Villa or Larger Property" ||
                        deepService.selectedCell.site === "Commercial") && (
                        <div className="site-city-selection-container deep-cleaning-area-container">
                          <label
                            htmlFor="site-city-select"
                            className="site-city-select-label"
                          >
                            Built up area
                          </label>
                          <input
                            className="deep-cleaning-area-input"
                            name="area"
                            value={deepService.area}
                            onChange={handleDeepChange}
                            placeholder="Built up area"
                          />
                        </div>
                      )}
                      <div className="site-service-description-container">
                        <p className="site-service-description">
                          {deepService.selectedCell.site !==
                            "4 BHK/Villa or Larger Property" &&
                          deepService.selectedCell.site !== "Commercial" ? (
                            <div>
                              <span>Total -</span>{" "}
                              <span style={{ fontWeight: "700" }}>
                                {" "}
                                {"₹ " + formatIndianNumber(deepService.price)}
                              </span>
                            </div>
                          ) : deepService.area ? (
                            <div>
                              <span>Total -</span>{" "}
                              <span style={{ fontWeight: "700" }}>
                                {" "}
                                {"₹ " + formatIndianNumber(deepService.price)}
                              </span>
                            </div>
                          ) : (
                            "Please enter build up area"
                          )}
                        </p>

                        <button
                          className="service-sending-button"
                          onClick={handleDeepSubmit}
                        >
                          Book
                        </button>
                        {siteService.error && (
                          <p className="error error-site">
                            {siteService.error === "location"
                              ? "Please select location"
                              : "Please select service"}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isServicePopup === "floor" && (
                  <div className="service-details">
                    <div className="service-title">Floor Protection</div>
                    <div className="debris-details-content">
                      <div className="debris-input-group">
                        <div className="debris-input-item">
                          <label
                            htmlFor="Floor area"
                            className="site-city-select-label"
                          >
                            Floor area
                          </label>
                          <div className="debris-input-field">
                            <input
                              type="text"
                              className="debris-load-input"
                              placeholder="Enter floor area"
                              value={floorService.sft}
                              name="sft"
                              onChange={handleFloorChange}
                            />
                            <span className="debris-load-unit">sft</span>
                          </div>
                        </div>
                        <div className="debris-rate-group">
                          <label
                            htmlFor="rate"
                            className="site-city-select-label"
                          >
                            Rate <span>(excl. of GST)</span>
                          </label>
                          <button className="debris-rate-button">
                            {floorService.price
                              ? `₹ ${formatIndianNumber(floorService.price)}`
                              : "-"}
                          </button>
                        </div>
                      </div>
                      <div className="debris-selection-group">
                        <div className="debris-input-item">
                          <label
                            htmlFor="site-city-select"
                            className="site-city-select-label"
                          >
                            Select your City
                          </label>
                          <select
                            className="landing-page-city-name site-city-select-dropdown"
                            name="location"
                            value={floorService.location}
                            onChange={handleFloorChange}
                          >
                            <option value="" disabled selected>
                              Select City
                            </option>
                            {cities.length > 0 &&
                              cities.map((city, index) => (
                                <option key={index} value={city}>
                                  {city}
                                </option>
                              ))}
                          </select>
                          {floorService.price ? (
                            <p className="debris-selection-summary">
                              Selected <span>{floorService.sft || 1}</span> sft
                              floor protection -{" "}
                              <span>{` ₹ ${formatIndianNumber(
                                floorService.price
                              )}`}</span>
                            </p>
                          ) : (
                            <p
                              className="debris-selection-summary"
                              style={{
                                color: floorService.error ? "red" : "initial",
                              }}
                            >
                              Please enter floor area
                            </p>
                          )}
                        </div>
                        <div className="debris-booking-button-container">
                          <button
                            className="service-booking-button"
                            onClick={handleFloorSubmit}
                          >
                            Book
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isServicePopup === "render" && (
                  <div className="service-details">
                    <div className="service-title">
                      3D Rendering Services & Walkthrough
                    </div>
                    <div className="debris-details-content floor-details-content render-details-content">
                      <div className="floor-data-container">
                        <p className="site-city-select-label floor-data-instructions">
                          I will provide :
                        </p>
                        <div className="floor-data-item-list">
                          {floorData.map((name, index) => (
                            <p
                              className={`service-booking-button floor-data-item ${
                                renderService.floor &&
                                renderService.floor === name
                                  ? "selected-floor-data-item"
                                  : ""
                              }`}
                              key={index}
                              onClick={() =>
                                setRenderServices((prevState) => ({
                                  ...prevState,
                                  floor: name,
                                  sft: "",
                                  sec: "",
                                  points: "",
                                  price: 0,
                                }))
                              }
                            >
                              <span>{name}</span>
                              {renderService.floor === name && (
                                <IoCheckmarkCircle className="selected-service-icon" />
                              )}
                            </p>
                          ))}
                        </div>
                      </div>

                      <div className="floor-data-container">
                        <p className="site-city-select-label floor-data-instructions">
                          I need : (Select one or more)
                        </p>
                        <div className="floor-data-item-list render-data-item-list">
                          {floorItems.map((name, index) => (
                            <div
                              key={index}
                              className="floor-data-item-input render-data-item-input"
                            >
                              <p
                                className={`service-booking-button floor-data-item render-data-item ${
                                  renderService.userProvide &&
                                  renderService.userProvide.includes(name)
                                    ? "selected-floor-data-item"
                                    : ""
                                }`}
                                onClick={() => handleRenderItemClick(name)}
                              >
                                <span>{name}</span>
                                {renderService.userProvide.includes(name) && (
                                  <IoCheckmarkCircle className="selected-service-icon" />
                                )}
                              </p>
                              {renderService.userProvide.includes(
                                "3D Renders"
                              ) &&
                                index === 0 && (
                                  <div className="debris-input-item floor-input-item render-input-item">
                                    <label
                                      htmlFor="Walkthrough"
                                      className="site-city-select-label"
                                    >
                                      Area to be rendered
                                    </label>
                                    <div className="debris-input-field">
                                      <input
                                        type="text"
                                        className="debris-load-input render-input-field"
                                        placeholder="Sft"
                                        value={
                                          renderService.sft
                                            ? renderService.sft
                                            : ""
                                        }
                                        name="sft"
                                        onChange={handleRenderChange}
                                      />
                                      <span className="debris-load-unit">
                                        sft
                                      </span>
                                    </div>
                                  </div>
                                )}

                              {renderService.userProvide.includes(
                                "Walkthrough"
                              ) &&
                                index === 1 && (
                                  <div className="debris-input-item floor-input-item render-input-item">
                                    <label
                                      htmlFor="Walkthrough"
                                      className="site-city-select-label"
                                    >
                                      Video Duration
                                    </label>
                                    <div className="debris-input-field">
                                      <input
                                        type="text"
                                        className="debris-load-input render-input-field"
                                        placeholder="Seconds"
                                        value={
                                          renderService.sec
                                            ? renderService.sec
                                            : ""
                                        }
                                        name="sec"
                                        onChange={handleRenderChange}
                                      />
                                      <span className="debris-load-unit">
                                        sec
                                      </span>
                                    </div>
                                  </div>
                                )}

                              {renderService.userProvide.includes(
                                "360° Virtual Tour"
                              ) &&
                                index === 2 && (
                                  <div className="debris-input-item floor-input-item render-input-item">
                                    <label
                                      htmlFor="Walkthrough"
                                      className="site-city-select-label"
                                    >
                                      View Points
                                    </label>
                                    <div className="debris-input-field ">
                                      <input
                                        type="text"
                                        className="debris-load-input render-input-field"
                                        placeholder="Points"
                                        value={
                                          renderService.points
                                            ? renderService.points
                                            : ""
                                        }
                                        name="points"
                                        onChange={handleRenderChange}
                                      />
                                      <span className="debris-load-unit">
                                        Points
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="debris-selection-group render-selection-group">
                        {renderService.price ? (
                          <p className="debris-selection-summary">
                            Total :{" "}
                            <span>
                              {`₹ ${formatIndianNumber(renderService.price)}`}{" "}
                            </span>
                            (excl. GST)
                          </p>
                        ) : (
                          <p
                            className="debris-selection-summary"
                            style={{
                              color: renderService.error ? "red" : "initial",
                            }}
                          >
                            Please choose a service and add a value
                          </p>
                        )}
                        <div className="debris-booking-button-container">
                          <button
                            className="service-booking-button"
                            onClick={handleRenderSubmit}
                          >
                            Book
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {paymentPopup && (
              <form
                className="service-customer-container"
                onSubmit={handlePayment}
              >
                <div className="service-customer-details">
                  <div className="service-customer-info">
                    <label htmlFor="Name">Name</label>
                    <input
                      className="service-customer-input"
                      type="text"
                      placeholder="Enter name"
                      value={paymentInput.name}
                      name="name"
                      onChange={handlePaymentInput}
                      required
                    />
                  </div>
                  <div className="service-customer-info">
                    <label htmlFor="Phone">Phone</label>
                    <input
                      className="service-customer-input"
                      type="text"
                      placeholder="Enter phone no"
                      value={paymentInput.phone}
                      name="phone"
                      onChange={handlePaymentInput}
                      required
                    />
                  </div>
                  <div className="service-customer-info">
                    <label htmlFor="GSTIN">GSTIN</label>
                    <input
                      className="service-customer-input"
                      type="text"
                      placeholder="Enter GST no"
                      value={paymentInput.gst}
                      name="gst"
                      onChange={handlePaymentInput}
                    />
                  </div>
                  <div className="service-customer-info">
                    <label htmlFor="BillingAddress">Billing Address</label>
                    <textarea
                      className="service-customer-input"
                      placeholder="Enter billing address"
                      cols="4"
                      rows="4"
                      value={paymentInput.billing}
                      name="billing"
                      onChange={handlePaymentInput}
                      required
                    />
                  </div>
                </div>
                <div className="service-customer-payment">
                  <p className="service-customer-payment-title">
                    Payment Details
                  </p>
                  <div className="service-customer-coupon">
                    <div className="service-customer-coupon-input">
                      <label htmlFor="Coupon">Have a coupon?</label>
                      <div>
                        <input
                          className="service-customer-input-coupon"
                          type="text"
                          placeholder="Enter coupon"
                          value={paymentInput.coupon}
                          name="coupon"
                          onChange={handlePaymentInput}
                        />
                        <p
                          className="service-customer-apply-coupon"
                          onClick={(e) =>
                            paymentInput.coupon && handleCoupon(e)
                          }
                          style={{
                            opacity: paymentInput.coupon ? 1 : 0.5,
                            color: paymentInput.price ? "#00ea00" : "",
                          }}
                        >
                          {paymentInput.price ? "Applied" : "Apply"}
                        </p>
                      </div>
                    </div>
                    <div className="service-customer-subtotal">
                      <p>Subtotal</p>
                      {isServicePopup === "site" && (
                        <p>{`${
                          "₹ " + formatIndianNumber(siteService.price)
                        }-`}</p>
                      )}
                      {isServicePopup === "render" && (
                        <p>{`₹ ${formatIndianNumber(renderService.price)}`}</p>
                      )}
                      {isServicePopup === "debris" && (
                        <p>{`₹ ${formatIndianNumber(debrisService.price)}`}</p>
                      )}
                      {isServicePopup === "floor" && (
                        <p>{`₹ ${formatIndianNumber(floorService.price)}`}</p>
                      )}
                      {isServicePopup === "deep" && (
                        <p>{`₹ ${formatIndianNumber(deepService.price)}`}</p>
                      )}
                    </div>
                    {paymentInput.price && (
                      <div
                        className="service-customer-subtotal"
                        style={{ color: "#00ea00" }}
                      >
                        <p>Coupon Discount</p>
                        <p>-₹ 500</p>
                      </div>
                    )}
                    <div className="service-customer-subtotal">
                      <p>18% GST</p>
                      {isServicePopup === "site" && !paymentInput.price && (
                        <p>{`${
                          "₹ " +
                          formatIndianNumber(
                            Math.ceil(siteService.price * 0.18)
                          )
                        }-`}</p>
                      )}
                      {isServicePopup === "render" && !paymentInput.price && (
                        <p>{`₹ ${formatIndianNumber(
                          Math.ceil(renderService.price * 0.18)
                        )}`}</p>
                      )}
                      {isServicePopup === "debris" && !paymentInput.price && (
                        <p>{`₹ ${formatIndianNumber(
                          Math.ceil(debrisService.price * 0.18)
                        )}`}</p>
                      )}
                      {isServicePopup === "floor" && !paymentInput.price && (
                        <p>{`₹ ${formatIndianNumber(
                          Math.ceil(floorService.price * 0.18)
                        )}`}</p>
                      )}
                      {isServicePopup === "deep" && !paymentInput.price && (
                        <p>{`₹ ${formatIndianNumber(
                          Math.ceil(deepService.price * 0.18)
                        )}`}</p>
                      )}
                      {paymentInput.price && (
                        <p>{`₹ ${formatIndianNumber(
                          Math.ceil(paymentInput.price * 0.18)
                        )}`}</p>
                      )}
                    </div>

                    <div className="service-customer-total">
                      <p>Order Total</p>
                      {isServicePopup === "site" && !paymentInput.price && (
                        <p>{`${
                          "₹ " +
                          formatIndianNumber(
                            siteService.price +
                              Math.ceil(siteService.price * 0.18)
                          )
                        }-`}</p>
                      )}
                      {isServicePopup === "render" && !paymentInput.price && (
                        <p>{`₹ ${formatIndianNumber(
                          renderService.price +
                            Math.ceil(renderService.price * 0.18)
                        )}`}</p>
                      )}
                      {isServicePopup === "debris" && !paymentInput.price && (
                        <p>{`₹ ${formatIndianNumber(
                          debrisService.price +
                            Math.ceil(debrisService.price * 0.18)
                        )}`}</p>
                      )}
                      {isServicePopup === "floor" && !paymentInput.price && (
                        <p>{`₹ ${formatIndianNumber(
                          floorService.price +
                            Math.ceil(floorService.price * 0.18)
                        )}`}</p>
                      )}
                      {isServicePopup === "deep" && !paymentInput.price && (
                        <p>{`₹ ${formatIndianNumber(
                          deepService.price +
                            Math.ceil(deepService.price * 0.18)
                        )}`}</p>
                      )}
                      {paymentInput.price && (
                        <p>{`₹ ${formatIndianNumber(
                          paymentInput.price +
                            Math.ceil(paymentInput.price * 0.18)
                        )}`}</p>
                      )}
                    </div>
                    <div className="service-customer-proceed-btn">
                      <button type="submit">Proceed</button>
                    </div>

                    <p className="service-customer-privacy-text">
                      *By signing up or placing an order, you're consenting to
                      our privacy policy.
                    </p>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesPopup;
