import React from "react";
import "./privacyPolicy.css";



function PrivacyPolicy() {
  
  return (
    <article className="privacy-policy-container">
      <h1 className="privacy-policy-title">Privacy Policy :</h1>
      <section className="privacy-policy-content">
        <p className="privacy-policy-intro">
          Interioverse ("we," "us," or "our") is committed to protecting the
          privacy and security of your personal information. This Privacy Policy
          outlines how we collect, use, disclose, and protect your information
          when you use our website, services, or mobile applications
          (collectively, the "Services").
        </p>

        <ol className="privacy-policy-list">
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Information We Collect</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Personal Information: When you register for an account or use
                our Services, we may collect personal information such as your
                name, email address, phone number, and billing information.
              </li>
              <li className="privacy-policy-detail">
                Design Information: We collect designs and project data that you
                create or upload while using our Services.
              </li>
              <li className="privacy-policy-detail">
                Usage Information: We may collect information about how you
                interact with our Services, such as your browsing history, IP
                address, device information, and location data.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">
              How We Use Your Information
            </h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                To Provide and Improve Our Services: We use your information to
                deliver personalized design solutions, improve our Services, and
                tailor your experience.
              </li>
              <li className="privacy-policy-detail">
                To Communicate with You: We may send you updates, notifications,
                and marketing communications about our Services.
              </li>
              <li className="privacy-policy-detail">
                To Protect Our Rights: We may use your information to detect,
                prevent, or address fraud, security breaches, or violations of
                our terms and policies.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">
              Sharing of Your Information
            </h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                With Third-Party Service Providers: We may share your
                information with third-party service providers who assist us in
                delivering our Services.
              </li>
              <li className="privacy-policy-detail">
                With Other Users: Your designs may be made accessible to other
                users of our platform, unless explicitly marked as private by
                you.
              </li>
              <li className="privacy-policy-detail">
                For Legal Compliance: We may disclose your information when
                required by law or in response to legal requests or government
                inquiries.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Your Choices</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Design Privacy: You have the option to mark your designs as
                private to restrict access by other users.
              </li>
              <li className="privacy-policy-detail">
                Communication Preferences: You can manage your communication
                preferences by adjusting your account settings or unsubscribing
                from marketing emails.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Data Security</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                We implement appropriate security measures to protect your
                information from unauthorized access, alteration, disclosure, or
                destruction.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">
              International Data Transfers
            </h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Your information may be transferred to and processed in
                countries outside of your jurisdiction, where data protection
                laws may differ.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Children's Privacy</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Our Services are not intended for children under the age of 13,
                and we do not knowingly collect personal information from
                children.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">
              Changes to this Privacy Policy
            </h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                We may update this Privacy Policy from time to time. We will
                notify you of any material changes by posting the updated policy
                on our website or through other communication channels.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Contact Us</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at
                enquiry@interioverse.com.
              </li>
            </ul>
          </li>
        </ol>

        <p className="privacy-policy-consent">
          By using our Services, you consent to the collection, use, and
          disclosure of your information as described in this Privacy Policy.
          Please review this policy carefully and contact us if you have any
          questions or concerns.
        </p>
      </section>
    </article>
  );
}

export default PrivacyPolicy;
