import React, { useEffect, useState } from "react";
import "./sideBar.css";
import gmail from "../../assets/gmail_logo.png";
import whatsapp from "../../assets/whatsapp_logo.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AXIOS from "../../services/axios";
import {
  checkForWarnings,
  extractCategoryInfo,
  formatIndianNumber,
} from "../../services/reusableCodes";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";

function Sidebar({
  summary,
  updateSave,
  areaTables,
  subTotalTables,
  setUpdateSave,
  color,
  font_family,
  projectId,
  version,
  interioverseRate,
  isCityFound,
  userInfo,
  otherCategoryCount,
  isWarning,
  setIsWarning
}) {
  const [sideBar, setSidebar] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const count = useSelector((state) => state.auth.count);
  const [membership, setMembership] = useState(false);

  useEffect(() => {
    if (
      pathname.startsWith("/new-project") ||
      pathname.startsWith("/my-project")
    ) {
      setSidebar("step1");
    } else if (pathname.startsWith("/my-quotation")) {
      setSidebar("step2");
    } else {
      setSidebar("step3");
    }
  }, [pathname]);

  const handleSave = async (e, method) => {
    e.preventDefault();

    if (checkForWarnings(areaTables)) {
      setIsWarning(true);
      setTimeout(() => {
        setIsWarning(false);
      }, 10000);
      return;
    }

    try {
      const mergedAreaTables = {
        ...subTotalTables,
        areas: areaTables.map((areaTable) => ({ ...areaTable })),
        background_color: color.background_color,
        table_header: color.table_header,
        table_font: color.table_font,
        table_border: color.table_border,
        table_amount: color.table_amount,
        amount_font: color.amount_font,
        font_family,
      };

      const response = await AXIOS.post(
        `/user-template/save-template/${projectId}/${version}`,
        {
          template: mergedAreaTables,
        }
      );
      if (response.status === 200) {
        setUpdateSave(true);
        setTimeout(() => {
          setUpdateSave(false);
        }, 3000);

        if (method === "continue") {
          navigate(`/my-quotation/${projectId}/${version}`);
        }
      }
    } catch (error) {
      console.log("Error saving data", error);
    }
  };

  return (
    <aside className="pdf-editor-side-bar-container">
      {sideBar === "step1" && (
        <div className="new-quotation-side-bar">
          <div className="new-quotation-side-bar-ad1"></div>

          <div className="new-quotation-side-bar-summary">
            <div className="summary-header">SUMMARY</div>

            <table className="summary-table">
              <tbody className="summary-body">
                {summary.area
                  .filter((area) => area.name) // Filter out areas without a name
                  .map((area, index) => (
                    <tr className="summary-row" key={index}>
                      <td className="summary-category">{area.name}</td>
                      <td className="summary-amount">
                        {area.value ? "₹ " + area.value : "0"}
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr className="summary-row">
                  <td className="summary-total-label">Sub Total</td>
                  <td className="summary-total-amount">{summary.subtotal}</td>
                </tr>
              </tfoot>
            </table>
            
            {!updateSave && (
              <div className="summary-buttons">
                <button
                  className="summary-button"
                  onClick={(e) => handleSave(e, "save")}
                >
                  Save
                </button>

                <button
                  className="summary-button"
                  onClick={(e) => handleSave(e, "continue")}
                >
                  Continue
                </button>
              </div>
            )}
            {isWarning && (
              <div className="error" style={{ textAlign: "center" }}>
                Kindly resolve all warnings before proceeding with saving.
              </div>
            )}

            {updateSave && (
              <div className="summary-buttons">
                <p>Saved Successfully</p>
              </div>
            )}
          </div>
          <div className="new-quotation-side-bar-ad2"></div>
        </div>
      )}

      {count === 0 && membership && (
        <section className="otp-popup">
          <div className="service-booking-container">
            <IoMdClose
              className="service-close-icon membership-close-icon"
              onClick={() => setMembership(false)}
            />
            <div className="membership-expiry-line">
              Your membership is expired - refer and get FREE
              membership. Click to{" "}
              <span
                onClick={() => {
                  navigate("/payment");
                  setMembership(false);
                }}
              >
                Redeem.
              </span>
              <div className="referral-instructions membership-referral-instructions">
                <p className="referral-details">To refer,</p>
                <p className="referral-details">
                  Ask the user to put your Profile ID as a referral code in the
                  purchase page.
                </p>
                <p className="referral-details">
                  1 Signup = Get 6 months FREE Classic Membership for yourself.
                  Your Referral gets 3 Months Free Classic membership.
                </p>
                <p className="referral-details">
                  2 Signups = Extend FREE classic membership to one year.
                </p>
                <p className="referral-details">
                  3 Signups = Add one more FREE year to your classic membership,
                  and so on...
                </p>
              </div>
            </div>
          </div>
        </section>
      )}

      {(sideBar == "step2" || sideBar == "step3") && (
        <div className="new-quotation-quote">
          {sideBar == "step2" && (
            <div className="new-quotation-quotation-share back-quotation-section">
              <button
                className="summary-button new-quotation-button"
                // onClick={() =>
                //   count === 0? setMembership(true) : window.open(
                //     `/final-quotation/${projectId}/${version}`,
                //     "_blank"
                //   )
                // }
                onClick={() =>
                   window.open(
                    `/final-quotation/${projectId}/${version}`,
                    "_blank"
                  )
                }
              >
                Generate PDF
              </button>

              <button
                className="summary-button new-quotation-back-button"
                onClick={() => {
                  navigate(`/my-project/${projectId}/${version}`);
                }}
              >
                Back
              </button>
            </div>
          )}

          <div className="new-quotation-content">
            {/* step 2 */}
            {sideBar == "step2" && isCityFound && (
              <div className="new-quotation-message">
                <p className="new-quotation-greeting">{`Hey ${userInfo.name}`}</p>
                <p className="new-quotation-notice">
                  We noticed that your quote value is :
                </p>
                <h3 className="new-quotation-quote-value">
                  {"₹ " + formatIndianNumber(subTotalTables.taxableAmount)}
                </h3>
                <p className="new-quotation-project-location">{`Interioverse can execute this project in ${userInfo.cityName} at `}</p>
                <h2 className="new-quotation-discounted-value">
                  {"₹ " + formatIndianNumber(interioverseRate.total)}
                </h2>
                <p className="new-quotation-total-value">
                  <span>
                    {"₹ " + formatIndianNumber(interioverseRate.gstTotal)}
                  </span>{" "}
                  (inc of GST)
                </p>
                <p className="new-quotation-commission">
                  And additionally, we pay you a{" "}
                  <span>commission of Rs. 7% </span>too.
                </p>
                <h2 className="new-quotation-switch-title">Switch to</h2>
                <button
                  type="button"
                  className="new-quotation-switch-button"
                  onClick={() =>
                    navigate(`/interioverse-quotation/${projectId}/${version}`)
                  }
                >
                  Interiorverse Quotation
                </button>
                <p className="new-quotation-client-title">Your client gets:</p>
                <div className="new-quotation-client-benefits">
                  <ul className="new-quotation-benefits-list">
                    <li>Excellent designer like you</li>
                    <li>Professional project Execution</li>
                    <li>Proper Time Tracking for Project</li>
                    <li>Dedicated CRM</li>
                    <li>Dedicated Project Manager</li>
                    <li>4 free Annual Service</li>
                    <li>FREE Home Automation for 4 points</li>
                  </ul>
                </div>
              </div>
            )}

            {/* step 3 */}
            {sideBar == "step3" && isCityFound && (
              <div className="new-quotation-message">
                <p className="interioverse-commit">
                  Hey, you made a great choice. Interioverse will execute your
                  project professionally and deliver it on time at
                </p>

                <h2 className="new-quotation-discounted-value">
                  {"₹ " + formatIndianNumber(subTotalTables.total)}
                </h2>
                <p className="new-quotation-total-value">All Taxes Included</p>
                <p className="new-quotation-commission download-sentence">
                  Please download the quotation and share it with your client.
                </p>

                <p className="new-quotation-commission">
                  Take the next step towards greater value! Contact Interioverse
                  for your project execution and experience the difference."
                </p>

                <button
                  type="button"
                  className="new-quotation-switch-button"
                  onClick={() =>
                   window.open(
                      `/final/interioverse-quotation/${projectId}/${version}`,
                      "_blank"
                    )
                  }
                >
                  Generate PDF
                </button>
              </div>
            )}

            {sideBar == "step2" && isCityFound && (
              <div className="new-quotation-contact-info">
                Contact +91 84488 56696 or write to partners@interioverse.com
                for further discussion.
              </div>
            )}

            {sideBar == "step3" && isCityFound && (
              <div
                className="new-quotation-contact-info"
                style={{ opacity: otherCategoryCount !== 0 ? 1 : 0 }}
              >
                {`Note: We have included your rate for ${otherCategoryCount} line items. Please
                contact Interioverse for quote verification`}
              </div>
            )}
          </div>
          {sideBar == "step3" && isCityFound && (
            <div className="new-quotation-quotation-share">
              <div className="summary-buttons new-quotation-action-buttons">
                {!pathname.startsWith("/my-quotation") && (
                  <div className="revert-back">
                    <h2 className="new-quotation-switch-title switch-to-older">
                      {" "}
                      The offer not to your liking at this time?
                    </h2>
                    <button
                      className="summary-button revert-back-button"
                      onClick={() =>
                        navigate(`/my-quotation/${projectId}/${version}`)
                      }
                    >
                      Revert Back
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </aside>
  );
}

export default Sidebar;
