// src/redux/store.js
import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'; // Note the default import
import authReducer from '../reducers/refreshToken.reduces';
import signupReducer from '../reducers/signUp.reducer';
import memberShipReducer from '../reducers/closeMembership.reducer';
import isMobileScreenReducer from '../reducers/isMobileScreen.reducer';


const rootReducer = combineReducers({
  auth: authReducer,
  signup: signupReducer,
  memberShip:memberShipReducer,
  isMobileScreen : isMobileScreenReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

