import React, { useEffect, useState } from "react";
import landingPageImg from "../../assets/Landing_page_picture.jpg";
import logo from "../../assets/logo.png";
import "./payment.css";
import cover from "../../assets/payment_cover.jpg";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import AXIOS from "../../services/axios";

function Payment() {
  const navigate = useNavigate();
  const [price, setPrice] = useState(null);
  const [referralCode, setReferralCode] = useState("");
  const [redeemError, setRedeemError] = useState("");
  const [redeemSuccess, setRedeemSuccess] = useState(false);

  useEffect(() => {
    const getPrice = async () => {
      try {
        const response = await AXIOS.get("/admin/get-price");
        if (response.status === 200) {
          setPrice(response.data.price);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getPrice();
  }, []);

  const initPayment = (data) => {
    const options = {
      key: process.env.REACT_APP_RAZOR_KEY, // Replace with your live Razorpay API key
      amount: data.amount,
      currency: data.currency,
      name: "Interioverse Design Pvt. Ltd.", // Update with your subscription plan name
      description: "Subscription Payment",
      image: logo,
      order_id: data.id,
      handler: async (response) => {
        try {
          const verifyUrl = "/payment/verify"; // Adjust endpoint as per your backend configuration
          const { data, status } = await AXIOS.post(verifyUrl, response);
          if (status === 200) {
            const response = await AXIOS.post("/order/create-order");
            navigate("/");
          }
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async () => {
    try {
      const orderUrl = "/payment/order"; // Adjust endpoint as per your backend configuration
      const { data } = await AXIOS.post(orderUrl, { amount: 2990 }); // Amount in INR
      initPayment(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setRedeemError("");
    setReferralCode(value);
  };

  const handleReferral = async () => {
    try {
      const response = await AXIOS.post("/order/referral", {
        referralCode: referralCode,
      });

      if (response.status === 200) {
        setReferralCode("");
        setRedeemSuccess(true);
        setTimeout(() => {
          setRedeemSuccess(false);
        }, 10000);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setRedeemError(error.response.data.error);
        } else if (error.response.status === 404) {
          setRedeemError("Enter a valid code");
        }
      }
    }
  };

  return (
    <section className="payment-section">
      <IoMdClose
        className="payment-close-icon"
        onClick={() => navigate("/pricing")}
      />
      <img src={landingPageImg} alt="Cover" className="cover-img" />
      <div className="payment-container">
        <h1 className="payment-title">Payment</h1>
        <div className="subscription-details">
          <div className="subscription-info">
            {/* <h2 className="subscription-plan-title">Interioverse’s Classic</h2>
            <h6 className="subscription-price">
              {`₹${price || 499}/-`}{" "}
              <span className="price-duration">per month</span>
            </h6> */}

            <div className="referral-code-section">
              <p className="referral-code-prompt">Have a Referral Code?</p>
              <div className="referral-code-input-group">
                <input
                  type="text"
                  placeholder="Add Referral Code"
                  className="referral-code-input"
                  value={referralCode}
                  onChange={handleChange}
                />
                <button
                  className="referral-code-redeem-button"
                  onClick={handleReferral}
                >
                  Redeem
                </button>
              </div>
              {/* <p className="referral-discount-message">
                <span className="referral-discount-code">INTVF38XXX</span> - 2%
                discount applied
              </p> */}

              {redeemError && (
                <p className="referral-discount-message error-redeem-message">
                  {redeemError}
                </p>
              )}
            </div>

            {/* {!redeemSuccess && (
              <div className="payment-order-summary">
                <div className="payment-summary-item">
                  <p className="payment-summary-label">Subtotal</p>
                  <p className="payment-summary-value">{`₹${price || 499}`}</p>
                </div>
                <div className="payment-summary-item">
                  <p className="payment-summary-label">Discount</p>
                  <p className="payment-summary-value">{`-₹${price || 499}`}</p>
                </div>
                <div className="payment-order-total">
                  <p className="payment-order-label">Order Total</p>
                  <p className="payment-order-value">₹490</p>
                </div>
                <div className="payment-newsletter-checkbox">
                  <input
                    type="checkbox"
                    className="newsletter-checkbox-input"
                  />
                  <span>Email me about new products, deals and more!</span>
                </div>
              </div>
            )} */}

            {redeemSuccess && (
              <div className="redeed-success-summary">
                <p>Congratulation, the code has been successfully redeemed</p>
                <p>90 days of FREE usages has been added to your membership</p>
                <p>TA DA ! Enjoy the platform !🤘</p>
              </div>
            )}
          </div>

          <div className="payment-method-section">
            <div className="payment-method-background">
              <img src={cover} alt="" className="payment-background-img" />
            </div>
            <div className="payment-method-content">
              {/* <p className="payment-method-title">Payment Method</p> */}
              <h2 className="subscription-plan-title">
                Interioverse’s Classic
              </h2>
              <h6 className="subscription-price">
                {`₹${price || 499}/-`}{" "}
                <span className="price-duration">per month</span>
              </h6>
              {/* <div className="payment-method-options">
                <p className="payment-option">UPI</p>
                <p className="payment-option">Net banking</p>
                <p className="payment-option">Credit Card</p>
                <p className="payment-option">Debit Card</p>
              </div> */}

              <div className="payment-order-summary">
                <div className="payment-summary-item">
                  <p className="payment-summary-label">Subtotal</p>
                  <p className="payment-summary-value">{`₹${price || 499}`}</p>
                </div>
                <div className="payment-order-total">
                  <p className="payment-order-label">Order Total</p>
                  <p className="payment-order-value">{`₹${price || 499}`}</p>
                </div>
              </div>

              <button onClick={handlePayment} className="proceed-button">
                Proceed
              </button>
              <p className="consent-message">
                *By signing up or placing an order, you're consenting to our{" "}
                <span
                  className="privacy-policy"
                  onClick={() => navigate("/privacy-policy")}
                >
                  privacy policy.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Payment;
