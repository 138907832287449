import React, { useEffect, useRef, useState } from "react";
import "./book-a-service-dropdown.css";

// Customized Dropdown Component
const CustomDropdown = ({
  projects,
  selectedProject,
  handleProjectChange,
  isDropdownOpen,
  setIsDropdownOpen,
}) => {
  const dropdownRef = useRef(null);

  // Handle dropdown toggle
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

   // Effect to handle clicks outside of the dropdown
   useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener("click", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setIsDropdownOpen]);

  return (
    <div className="book-a-service-custom-dropdown" ref={dropdownRef}>
      <button
        className="book-a-service-dropdown-toggle"
        onClick={toggleDropdown}
      >
        {selectedProject.selectedProjectName ? (
          <div>
            <span className="book-a-service-selected-project-name">
              {selectedProject.selectedProjectName}
            </span>
            <span>{` - ${selectedProject.selectedVersionName}`}</span>
          </div>
        ) : (
          "Select a Project"
        )}
        <span className="book-a-service-dropdown-arrow">
          {isDropdownOpen ? "▲" : "▼"}
        </span>
      </button>

      {isDropdownOpen && (
        <div className="book-a-service-dropdown-menu">
          {projects &&
            projects.map((project, index) => (
              <div key={index} className="book-a-service-dropdown-group">
                <div className="book-a-service-dropdown-group-label">
                  {project.project_name}
                </div>
                {project.versions.map((version, versionIndex) => (
                  <div
                    key={versionIndex}
                    className="book-a-service-dropdown-item"
                    onClick={() =>
                      handleProjectChange(
                        project.project_id,
                        version.version_name
                      )
                    }
                  >
                    {version.version_name}
                  </div>
                ))}
              </div>
            ))}
          {!projects && <div className="book-a-service-no-prpjects">
            No projects available.</div>}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
