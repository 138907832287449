import React from 'react';
import logo from "../../assets/logo.png";
import './loading.css';

function Loading() {
  return (
    <div className="loading-container">
      <div className="loading-spinner">
        <div className="loading-circle"></div>
        <img src={logo} alt="loading section" className="loading-logo" />
      </div>
      <p className="loading-text">Loading...</p>
    </div>
  );
}

export default Loading;


