import React, { useEffect, useState } from "react";
import sample from "../../assets/pdf/sample.pdf";
import "./pdfViewer.css";
import p1 from "../../assets/pdf/p1.jpg";
import p2 from "../../assets/pdf/p2.jpg";
import p3 from "../../assets/pdf/p3.jpg";
import p4 from "../../assets/pdf/p4.jpg";
import p5 from "../../assets/pdf/p5.jpg";
import p6 from "../../assets/pdf/p6.jpg";
import p7 from "../../assets/pdf/p7.jpg";
import p8 from "../../assets/pdf/p8.jpg";
import p9 from "../../assets/pdf/p9.jpg";
import p10 from "../../assets/pdf/p10.jpg";

const PdfViewer = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const pdfImages = [ p9,p10,p6, p7,p8,p1,p2,p3,p4, p5,];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === pdfImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);
    return () => clearInterval(interval);
  }, [pdfImages]);


  return (
    <div className="pdf-viewer">
      <div className="pdf-viewer-container">
        <iframe
          src={sample + "#toolbar=0"}
          width="100%"
          height="100%"
          className="pdf-viewer-iframe"
          title="PDF Viewer"
          frameBorder="0"
          scrolling="no"
          seamless="seamless"
        />
      </div>
      <div className="pdf-viewer-images">
      <h2>A Quick Glimpse of Interior Projects Quote</h2>
        <div className="sliderImage">
          {pdfImages.map((image, index) => (
            <img
              key={index}
              src={image} alt={`PDF page ${index + 1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
                position: index === currentIndex ? "static" : "absolute",
                opacity: index === currentIndex ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
