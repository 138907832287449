// src/redux/reducers/signupReducer.js
import { SET_IS_CLOSE } from '../actions/refreshToken.action';

const initialState = {
  isClose: true, // Default state is false
};

const memberShipReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_CLOSE:
      return {
        ...state,
        isClose: action.payload,
      };
    default:
      return state;
  }
};

export default memberShipReducer;
