import React, { useEffect, useState } from "react";
import landingPageImg from "../../assets/Landing_page_picture.jpg";
import "./partner.css";
import CustomRadioButton from "../../components/radioBtn/radioBtn";
import { IoIosCheckmarkCircleOutline, IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AXIOS from "../../services/axios";
import { refreshToken } from "../../services/redux/thunk/authThunk";
import { useDispatch } from "react-redux";

function PartnerSignup() {
  const [formData, setFormData] = useState({
    role: "Contractor",
    base_location: "",
    specialization: "",
    experience: "",
    project_volume: "",
    linkedin_profile: "",
    instagram_profile: "",
    purpose: "",
  });
  const [cities, setCities] = useState("");
  const navigate = useNavigate();
  const [isSignup, setIsSignup] = useState({
    forVerify: false,
    notVerify: false,
  });
  const dispatch = useDispatch();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await AXIOS.get("/all-cities-list");
        if (response.status === 200) {
          setCities(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCities();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "role") {
      setFormData({
        role: value,
        base_location: "",
        specialization: "",
        experience: "",
        project_volume: "",
        linkedin_profile: "",
        instagram_profile: "",
        purpose: "",
        sales_volume: "",
      });
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Destructure formData for readability
    const {
      role,
      base_location,
      specialization,
      experience,
      project_volume,
      linkedin_profile,
      instagram_profile,
      sales_volume,
      purpose,
    } = formData;

    // Determine dataToSend based on role
    let dataToSend = { role, base_location };

    switch (role) {
      case "Contractor":
        dataToSend = { ...dataToSend, specialization, experience };
        break;
      case "Designer":
        dataToSend = {
          ...dataToSend,
          specialization,
          experience,
          project_volume,
          linkedin_profile,
          instagram_profile,
        };
        break;
      case "Real Estate":
        dataToSend = { ...dataToSend, experience, sales_volume };
        break;
      case "Digital Creator":
        dataToSend = { ...dataToSend, purpose };
        break;
      default:
        break;
    }

    try {
      const response = await AXIOS.post("/user/user-info", dataToSend);

      if (response.status === 200) {
        // Update isSignup state based on role
        setIsSignup((prev) => ({
          ...prev,
          forVerify:
            role === "Designer" || role === "Real Estate" ? true : false,
          notVerify:
            role !== "Designer" && role !== "Real Estate" ? true : false,
        }));

        // Reset formData
        setFormData({
          role: "Contractor",
          base_location: "",
          specialization: "",
          experience: "",
          project_volume: "",
          linkedin_profile: "",
          instagram_profile: "",
          purpose: "",
        });

        if (role === "Designer") {
          AXIOS.post("/email/send-welcome");
        }

        dispatch(refreshToken());
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Request failed:", error.message);
    }
  };

  const getFilteredCities = () => {
    if (formData.base_location.length > 1) {
      return cities
        .filter((city) =>
          city.toLowerCase().includes(formData.base_location.toLowerCase())
        )
        .slice(0, 5);
    }
    return [];
  };

  const handleLocationInput= (e) => {
    handleChange(e);
    setDropdownVisible(e.target.value.length > 1);
  };

  const handleOptionSelect = (city) => {
    handleChange({ target: { name: "base_location", value: city } });
    setDropdownVisible(false);
  };

  const filteredCities = getFilteredCities();

  return (
    <div style={{ margin: "0px", padding: "0" }}>
      <div className="partner-container">
        <figure>
          <img
            src={landingPageImg}
            alt="Cover img"
            className={`partner-cover-img ${
              formData.role === "Designer" ? "designer-partner-cover-img" : ""
            } ${
              formData.role === "Digital Creator" ||
              formData.role === "Real Estate"
                ? "realestate-commercial-partner-cover-img"
                : ""
            }`}
          />
        </figure>

        {(isSignup.forVerify || isSignup.notVerify) && (
          <section className="otp-popup partner-verified-popup">
            <div className="otp-container partner-verified-container">
              <IoMdClose
                className="otp-close partner-verified-close "
                onClick={() => {
                  setIsSignup({ forVerify: false, notVerify: false });
                  navigate("/");
                }}
              />
              <div className="otp-content partner-verified-content">
                <div className="verified-icon-container">
                  <IoIosCheckmarkCircleOutline className="verified-icon" />
                </div>
                <h2
                  className={`verified-success-heading partner-verified-success-heading ${
                    isSignup.forVerify
                      ? "verification-processing-time partner-verification-processing-time"
                      : ""
                  }`}
                  style={{ opacity: isSignup.forVerify ? "0.8" : "1" }}
                >
                  {isSignup.forVerify
                    ? "Request successfully submitted."
                    : "Your partnership registration is successful."}
                </h2>
                <p className="verification-notice partner-verification-notice">
                  {isSignup.forVerify ? (
                    <p className="schedule-link-text">
                      Please schedule the KYC Verification meeting from this{" "}
                      <a
                        href="https://calendly.com/partners-interioverse/design-partner-s-verification"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="schedule-link"
                      >
                        Link
                      </a>
                    </p>
                  ) : (
                    "Welcome to Interioverse family !"
                  )}
                </p>
                <p className="verification-processing-time partner-verification-processing-time">
                  {isSignup.forVerify
                    ? "Your account will be activated only after the verification meeting with Interioverse."
                    : "You can now unlock many features and do wonders with Interioverse."}
                </p>
              </div>
            </div>
          </section>
        )}

        {!isSignup.forVerify && !isSignup.notVerify && (
          <section className="partner-section">
            <div className="partner-content">
              <IoMdClose
                className="partner-close-icon"
                onClick={() => navigate("/")}
              />
              <h1 className="partner-title">Be a Partner</h1>
              <form
                className={`partner-form ${
                  formData.role === "Digital Creator" ||
                  formData.role === "Real Estate"
                    ? "realestate-commercial-partner-form"
                    : ""
                } ${
                  formData.role === "Designer" ? "designer-partner-form" : ""
                }`}
              >
                <div>
                  {/* I am input  */}
                  <div className="partner-form-group">
                    <label htmlFor="role" className="partner-form-label">
                      I am:
                    </label>
                    <div className="partner-radio-selection">
                      <div className="partner-radio-option">
                        <CustomRadioButton
                          id="contractor"
                          name="role"
                          checked={formData.role === "Contractor"}
                          onChange={handleChange}
                          value="Contractor"
                          className="radio-button"
                        />
                        <p className="partner-radio-label">Contractor</p>
                      </div>
                      <div className="partner-radio-option">
                        <CustomRadioButton
                          id="designer"
                          name="role"
                          checked={formData.role === "Designer"}
                          onChange={handleChange}
                          value="Designer"
                          className="radio-button"
                        />
                        <p className="partner-radio-label">Designer</p>
                      </div>
                      <div className="partner-radio-option">
                        <CustomRadioButton
                          id="real_estate"
                          name="role"
                          checked={formData.role === "Real Estate"}
                          onChange={handleChange}
                          value="Real Estate"
                          className="radio-button"
                        />
                        <p className="partner-radio-label">Real Estate</p>
                      </div>
                      <div className="partner-radio-option">
                        <CustomRadioButton
                          id="digital_creator"
                          name="role"
                          checked={formData.role === "Digital Creator"}
                          onChange={handleChange}
                          value="Digital Creator"
                          className="radio-button"
                        />
                        <p className="partner-radio-label">Digital Creator</p>
                      </div>
                    </div>
                  </div>

                  {/* base location  */}
                  <div className="partner-form-group">
                    <label
                      htmlFor="base-location"
                      className="partner-form-label"
                    >
                      Base Location:
                    </label>
                    <div className="dropdown-container ">
                    <input
                      type="text"
                      id="base-location"
                      placeholder="Enter your city"
                      className="partner-form-input"
                      onChange={handleLocationInput}
                      name="base_location"
                      value={formData.base_location}
                      required
                      list={
                        formData.base_location.length > 1
                          ? "project-location-list"
                          : ""
                      }
                      autoComplete="off"
                    />
                    {isDropdownVisible && (
                      <ul className="dropdown-list parter-dropdown-list" >
                        {filteredCities.length > 0 && (
                          filteredCities.map((city, index) => (
                            <li
                              key={index}
                              className="dropdown-item"
                              onClick={() => handleOptionSelect(city)}
                            >
                              {city}
                            </li>
                          ))
                        ) }
                      </ul>
                    )}
                    </div>
                  </div>

                  {/* specialization  */}
                  {(formData.role == "Contractor" ||
                    formData.role == "Designer") && (
                    <div className="partner-form-group">
                      <label
                        htmlFor="base-location"
                        className="partner-form-label"
                      >
                        Specialization:
                      </label>
                      <input
                        type="text"
                        placeholder="Select your specialization"
                        className="project-form-input partner-input"
                        readOnly
                        onChange={handleChange}
                        name="specialization"
                        value={formData.specialization}
                        required
                      />

                      {/* specialization option for contractor  */}
                      {formData.role == "Contractor" && (
                        <div className="partner-radio-selection">
                          <div className="partner-radio-option partner-radio-option-width">
                            <CustomRadioButton
                              id="wood_work"
                              name="specialization"
                              checked={formData.specialization === "Wood work"}
                              onChange={handleChange}
                              value="Wood work"
                              className="radio-button"
                            />
                            <p className="partner-radio-label">Wood work</p>
                          </div>
                          <div className="partner-radio-option partner-radio-option-width">
                            <CustomRadioButton
                              id="painting"
                              name="specialization"
                              checked={formData.specialization === "Painting"}
                              onChange={handleChange}
                              value="Painting"
                              className="radio-button"
                            />
                            <p className="partner-radio-label">Painting</p>
                          </div>
                          <div className="partner-radio-option partner-radio-option-width">
                            <CustomRadioButton
                              id="tiles_work"
                              name="specialization"
                              checked={formData.specialization === "Tiles work"}
                              onChange={handleChange}
                              value="Tiles work"
                              className="radio-button"
                            />
                            <p className="partner-radio-label">Tiles work</p>
                          </div>
                          <div className="partner-radio-option partner-radio-option-width">
                            <CustomRadioButton
                              id="others"
                              name="specialization"
                              checked={formData.specialization === "Others"}
                              onChange={handleChange}
                              value="Others"
                              className="radio-button"
                            />
                            <p className="partner-radio-label">Others</p>
                          </div>
                        </div>
                      )}

                      {/* specialization option for Designer  */}
                      {formData.role == "Designer" && (
                        <div className="partner-radio-selection">
                          <div className="partner-radio-option partner-radio-option-width">
                            <CustomRadioButton
                              id="affordable_home_interior"
                              name="specialization"
                              checked={
                                formData.specialization ===
                                "Affordable Home Interior"
                              }
                              onChange={handleChange}
                              value="Affordable Home Interior"
                              className="radio-button"
                            />
                            <p className="partner-radio-label">
                              Affordable Home Interior
                            </p>
                          </div>
                          <div className="partner-radio-option partner-radio-option-width">
                            <CustomRadioButton
                              id="luxury_home_interior"
                              name="specialization"
                              checked={
                                formData.specialization ===
                                "Luxury Home Interior"
                              }
                              onChange={handleChange}
                              value="Luxury Home Interior"
                              className="radio-button"
                            />
                            <p className="partner-radio-label">
                              Luxury Home Interior
                            </p>
                          </div>
                          <div className="partner-radio-option partner-radio-option-width">
                            <CustomRadioButton
                              id="office_interior"
                              name="specialization"
                              checked={
                                formData.specialization === "Office Interior"
                              }
                              onChange={handleChange}
                              value="Office Interior"
                              className="radio-button"
                            />
                            <p className="partner-radio-label">
                              Office Interior
                            </p>
                          </div>
                          <div className="partner-radio-option partner-radio-option-width">
                            <CustomRadioButton
                              id="ccommercial_interior"
                              name="specialization"
                              checked={
                                formData.specialization ===
                                "Showroom/Commercial Interior"
                              }
                              onChange={handleChange}
                              value="Showroom/Commercial Interior"
                              className="radio-button"
                            />
                            <p className="partner-radio-label">
                              Showroom/Commercial Interior
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {/* current workload per quarter  */}
                  {formData.role == "Designer" && (
                    <div className="partner-form-group">
                      <label
                        htmlFor="base-location"
                        className="partner-form-label"
                      >
                        Your current workload per quarter:
                      </label>
                      <input
                        type="text"
                        placeholder="Select your project volume"
                        className="project-form-input partner-input"
                        readOnly
                        onChange={handleChange}
                        name="project_volume"
                        value={formData.project_volume}
                        required
                      />
                      <div className="partner-radio-selection">
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="0-2"
                            name="project_volume"
                            checked={formData.project_volume === "0-2"}
                            onChange={handleChange}
                            value="0-2"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">0-2</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="2-5"
                            name="project_volume"
                            checked={formData.project_volume === "2-5"}
                            onChange={handleChange}
                            value="2-5"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">2-5</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="5-10"
                            name="project_volume"
                            checked={formData.project_volume === "5-10"}
                            onChange={handleChange}
                            value="5-10"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">5-10</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="10+"
                            name="project_volume"
                            checked={formData.project_volume === "10+"}
                            onChange={handleChange}
                            value="10+"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">10+</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* experience */}
                  {formData.role !== "Digital Creator" && (
                    <div className="partner-form-group">
                      <label
                        htmlFor="base-location"
                        className="partner-form-label"
                      >
                        Experience:
                      </label>
                      <input
                        type="text"
                        placeholder="Selet your experience"
                        className="project-form-input partner-input"
                        readOnly
                        onChange={handleChange}
                        name="experience"
                        value={formData.experience}
                        required
                      />
                      <div className="partner-radio-selection">
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="0-2_Years"
                            name="experience"
                            checked={formData.experience === "0-2 Years"}
                            onChange={handleChange}
                            value="0-2 Years"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">0-2 Years</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="2-5_Years"
                            name="experience"
                            checked={formData.experience === "2-5 Years"}
                            onChange={handleChange}
                            value="2-5 Years"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">2-5 Years</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="5-10_Years"
                            name="experience"
                            checked={formData.experience === "5-10 Years"}
                            onChange={handleChange}
                            value="5-10 Years"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">5-10 Years</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="10-15_Years"
                            name="experience"
                            checked={formData.experience === "10-15 Years"}
                            onChange={handleChange}
                            value="10-15 Years"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">10-15 Years</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="15+_Years"
                            name="experience"
                            checked={formData.experience === "15+ Years"}
                            onChange={handleChange}
                            value="15+ Years"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">15+ Years</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* sales volume  */}
                  {formData.role == "Real Estate" && (
                    <div className="partner-form-group">
                      <label
                        htmlFor="base-location"
                        className="partner-form-label"
                      >
                        Sales volume per month:
                      </label>
                      <input
                        type="text"
                        placeholder="No of deals closed"
                        className="project-form-input partner-input"
                        readOnly
                        onChange={handleChange}
                        name="sales_volume"
                        value={formData.sales_volume}
                        required
                      />
                      <div className="partner-radio-selection">
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="0-2_Deals"
                            name="sales_volume"
                            checked={formData.sales_volume === "0-2 Deals"}
                            onChange={handleChange}
                            value="0-2 Deals"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">0-2 Deals</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="2-6_Deals"
                            name="sales_volume"
                            checked={formData.sales_volume === "2-6 Deals"}
                            onChange={handleChange}
                            value="2-6 Deals"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">2-6 Deals</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="6-12_Deals"
                            name="sales_volume"
                            checked={formData.sales_volume === "6-12 Deals"}
                            onChange={handleChange}
                            value="6-12 Deals"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">6-12 Deals</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="12-20_Deals"
                            name="sales_volume"
                            checked={formData.sales_volume === "12-20 Deals"}
                            onChange={handleChange}
                            value="12-20 Deals"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">12-20 Deals</p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="20+_Deals"
                            name="sales_volume"
                            checked={formData.sales_volume === "20+ Deals"}
                            onChange={handleChange}
                            value="20+ Deals"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">20+ Deals</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* digital creator purposes  */}
                  {formData.role == "Digital Creator" && (
                    <div className="partner-form-group">
                      <label
                        htmlFor="base-location"
                        className="partner-form-label"
                      >
                        Purposes:
                      </label>
                      <input
                        type="text"
                        placeholder="No of deals closed"
                        className="project-form-input partner-input"
                        readOnly
                        onChange={handleChange}
                        name="purpose"
                        value={formData.purpose}
                        required
                      />
                      <div className="partner-radio-selection">
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="academic_student"
                            name="purpose"
                            checked={formData.purpose === "Academic/Student"}
                            onChange={handleChange}
                            value="Academic/Student"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">
                            Academic/Student
                          </p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="passion_for_Self"
                            name="purpose"
                            checked={formData.purpose === "Passion/for Self"}
                            onChange={handleChange}
                            value="Passion/for Self"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">
                            Passion/for Self
                          </p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="additiona_income"
                            name="purpose"
                            checked={formData.purpose === "Additional Income"}
                            onChange={handleChange}
                            value="Additional Income"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">
                            Additional Income
                          </p>
                        </div>
                        <div className="partner-radio-option partner-radio-option-width">
                          <CustomRadioButton
                            id="just_exploring"
                            name="purpose"
                            checked={formData.purpose === "Just Exploring"}
                            onChange={handleChange}
                            value="Just Exploring"
                            className="radio-button"
                          />
                          <p className="partner-radio-label">Just Exploring</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* linkedin profile */}
                  {formData.role == "Designer" && (
                    <div className="partner-form-group">
                      <label
                        htmlFor="base-location"
                        className="partner-form-label"
                      >
                        Linkedin Profile:
                      </label>
                      <input
                        type="text"
                        id="linkedin_profile"
                        placeholder="Profile link"
                        className="partner-form-input"
                        onChange={handleChange}
                        name="linkedin_profile"
                        value={formData.linkedin_profile}
                      />
                    </div>
                  )}

                  {/* instagram profile  */}
                  {formData.role == "Designer" && (
                    <div
                      className="partner-form-group"
                      style={{ marginTop: "20px" }}
                    >
                      <label
                        htmlFor="base-location"
                        className="partner-form-label"
                      >
                        Instagram Profile:
                      </label>
                      <input
                        type="text"
                        id="instagram_profile"
                        placeholder="Profile link"
                        className="partner-form-input"
                        onChange={handleChange}
                        name="instagram_profile"
                        value={formData.instagram_profile}
                      />
                    </div>
                  )}

                  {/* note  */}
                  {(formData.role == "Real Estate" ||
                    formData.role == "Designer") && (
                    <p className="partner-note">
                      Note:{" "}
                      <span>
                        {formData.role == "Designer"
                          ? "Designer’s"
                          : "Real estate"}
                      </span>{" "}
                      account will be activated only after verification and may
                      take up to 48 hrs.
                    </p>
                  )}
                </div>

                <div className="partner-submit-btn">
                  <button type="submit" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default PartnerSignup;
