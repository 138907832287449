import React from 'react';
import './radioBtn.css'; // Import your CSS styles

const CustomRadioButton = ({ id, name, value, label, checked, onChange }) => {
  return (
    <div className="custom-radio">
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="custom-radio-label">
        {label}
      </label>
    </div>
  );
};

export default CustomRadioButton;

