import React, { useEffect, useState } from "react";
import { TfiPinterest } from "react-icons/tfi";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import logo from "../../assets/interioverse_logo.png";
import pinterest from "../../assets/Pinterest-logo.png";
import linkedin from "../../assets/LinkedIn_logo.png";
import { useNavigate } from "react-router-dom";

import "./footer.css";
import AXIOS from "../../services/axios";

function Footer() {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [cities,setCities] = useState('')

  const fetchCities = async () => {
    try {
      const response = await AXIOS.get("/admin/admin-cities");
      if (response.status === 200) {
        setCities(response.data.cities);
      }
    }  catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    fetchCities()
  },[])

  return (
    <footer>
      <article>
        <section className="footer-section">
          <section className="footer-locations">
            <ul className="footer-locations-list">
              <li >{cities && cities.map(city => city.toUpperCase()).join(" | ")}</li>
            </ul>
          </section>

          <section className="footer-navigation">
            <img src={logo} alt="" className="footer-navigation-img"/>
            <nav aria-label="Footer Navigation">
              <ul className="footer-nav-list">
                <li
                  className="footer-nav-item"
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </li>
                <li
                  className="footer-nav-item"
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Policy
                </li>
                <li
                  className="footer-nav-item"
                  onClick={() => navigate("/shipping-delivery")}
                >
                  Shipping and Delivery
                </li>
                <li
                  className="footer-nav-item"
                  onClick={() => navigate("/cancellation-refund")}
                >
                  Cancellation and Refund
                </li>
                <li
                  className="footer-nav-item"
                  onClick={() => navigate("/terms-conditions")}
                >
                  Terms and Conditions
                </li>
              </ul>
            </nav>

            <nav aria-label="Social Media Navigation">
              <ul className="footer-social-media-list">
                <li className="footer-social-media-fb">
                  <a
                    href="https://www.facebook.com/interioversedesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF className="footer-fb-icon" />
                  </a>
                </li>
                <li className="footer-social-media-pinterest">
                  <a
                    href="https://in.pinterest.com/interioversedesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={pinterest}
                      alt=""
                      className="footer-pinterest-icon"
                    />
                  </a>
                </li>
                <li className="footer-social-media-insta">
                  <a
                    href="https://www.instagram.com/interioversedesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoInstagram className="footer-insta-icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/interioverse-design-5ba461276/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={linkedin}
                      alt=""
                      className="footer-linkedin-icon"
                    />
                  </a>
                </li>
                <li className="footer-social-media-youtube">
                  <a
                    href="https://www.youtube.com/@InterioverseDesign"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube className="footer-youtube-icon" />
                  </a>
                </li>
              </ul>
            </nav>
          </section>

          <section>
            <ul className="mobile-footer-nav-list">
              <li
                className="mobile-footer-nav-item"
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </li>
              <li
                className="mobile-footer-nav-item"
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </li>
              <li
                className="mobile-footer-nav-item"
                onClick={() => navigate("/shipping-delivery")}
              >
                Shipping and Delivery
              </li>
              <li
                className="mobile-footer-nav-item"
                onClick={() => navigate("/cancellation-refund")}
              >
                Cancellation and Refund
              </li>
              <li
                className="mobile-footer-nav-item"
                onClick={() => navigate("/terms-conditions")}
              >
                Terms and Conditions
              </li>
            </ul>
          </section>
          <p className=" mobile-footer-Incubatee">Incubatee of AIC-SMUTBI</p>
          {/* <div className="sample-unity-button">
            <a href="/unity" target="_blank" rel="noopener noreferrer">
              <button>Unity</button>
            </a>
          </div> */}
        </section>

        <section className="footer-contact">
          <p className="footer-copyright" onClick={() =>
                    window.open(`/unity`, '_blank')
                  }>
            Copyright © Interioverse {currentYear}. All rights reserved.
          </p>
          <p className="footer-copyright footer-Incubatee">
            Incubatee of AIC-SMUTBI
          </p>
          <p className="footer-ontact-info">
            +91 8448856696 | enquiry@interioverse.com
          </p>
        </section>
      </article>
    </footer>
  );
}

export default Footer;
