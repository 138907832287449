import React from "react";

function CancellationRefund() {
  return (
    // using privacy policy css

    <article className="privacy-policy-container">
      <h1 className="privacy-policy-title">Payment Terms and Refund Policy :</h1>

      <section className="privacy-policy-content">
        <ol className="privacy-policy-list">
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">
              {" "}
              Subscription/Virtual Showroom
            </h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Monthly subscriptions are non-refundable and require payment in
                advance.
              </li>
              <li className="privacy-policy-detail">
                Annual subscriptions are refundable with the refund amount
                calculated based on the monthly billing price.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">
              {" "}
              Raw Materials, Furniture, and Decor Items
            </h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Purchases are refundable only in the case of manufacturing
                defects.{" "}
              </li>
              <li className="privacy-policy-detail">
                {" "}
                Users must contact enquiry@interioverse.com to initiate the
                refund process.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">
              {" "}
              Interior Project Execution
            </h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                {" "}
                To initiate the project, 10% of the quoted value is required.
              </li>
              <li className="privacy-policy-detail">
                {" "}
                After design finalization, 60% of the quoted value must be paid
                before execution.{" "}
              </li>
              <li className="privacy-policy-detail">
                {" "}
                Prior to woodwork or material delivery, an additional 25% of the
                quoted value is due.
              </li>
              <li className="privacy-policy-detail">
                The remaining 5% is payable upon project completion.{" "}
              </li>
              <li className="privacy-policy-detail">
                No refunds are entertained once payment is made, as payments are
                made phase-wise.{" "}
              </li>
              <li className="privacy-policy-detail">
                Warranties and services are terminated if 100% of the payment
                from the project is not realized by Interioverse.{" "}
              </li>
            </ul>
          </li>
        </ol>
        <p className="privacy-policy-consent">
          By engaging with Interioverse, you agree to adhere to these payment
          terms and refund policies. For any queries or clarifications, please
          contact us at enquiry@interioverse.com.
        </p>
      </section>
    </article>
  );
}

export default CancellationRefund;
