import axios from "axios";

// Define your base URL
// const baseURL = "http://localhost:5000/v1";

const baseURL = process.env.REACT_APP_BASE_URL; 

// Create an instance of Axios with the base URL
const AXIOS = axios.create({
  baseURL,
  withCredentials: true,
}); 

export default AXIOS;
