import React, { useEffect, useRef, useState } from "react";
import "./finishes-types.css";
import AXIOS from "../../services/axios";
import {
  fetchUserRates,
  lightenColor,
  minusAmount,
  updateAmount,
  updateAreaAmount,
  updateItemAmount,
  updateSubTotalAmount,
} from "../../services/reusableCodes";

function FinishesTypes({
  setActiveDropdown,
  tableDetails,
  setAreaTables,
  setRateTables,
  rates,
  setRates,
  table_header,
  table_font,
  finishesData,
}) {

  const [expandedFinish, setExpandedFinish] = useState(null);
  const [hoveredType, setHoveredType] = useState(null);
  const wrapperRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");



  const toggleFinish = (index) => {
    setExpandedFinish(expandedFinish === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setExpandedFinish(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  

  const selectFinishType = (
    categoryId,
    finishId,
    typeId,
    category,
    finish,
    type,
    unit,
    range,
    rates,
    description
  ) => {
    const {
      tableId,
      areaId,
      itemId,
      subItemId,
      subIndex,
      setSubTotalTables,
      subTotalTables,
    } = tableDetails;

    // Find the matching rate
    const matchingRate = rates.find(
      (rate) =>
        rate.category === categoryId &&
        rate.finish === finishId &&
        rate.type === typeId
    );

    const rateValue = matchingRate ? matchingRate.rate : "";

    // Update the state of areaTables
    setAreaTables((currentTables) =>
      currentTables.map((table) =>
        table.id === tableId
          ? {
              ...table,
              area: table.area.map((area) =>
                area.id === areaId
                  ? {
                      ...area,
                      items: area.items.map((item) =>
                        item.id === itemId
                          ? {
                              ...item,
                              subItems: item.subItems.map((subItem) =>
                                subItem.id === subItemId
                                  ? {
                                      ...subItem,
                                      category: category,
                                      finish: finish,
                                      type: type,
                                      categoryId: categoryId,
                                      finishId: finishId,
                                      typeId: typeId,
                                      description: description,
                                      unit: unit,
                                      range: range,
                                      height: "",
                                      width: "",
                                      interioverseRate:false,
                                      rate: parseFloat(rateValue)
                                        ? parseFloat(rateValue)
                                        : "",
                                        warning : ""
                                    }
                                  : subItem
                              ),
                            }
                          : item
                      ),
                    }
                  : area
              ),
            }
          : table
      )
    );

    // Reset active dropdown and expanded finish
    setActiveDropdown(null);
    setExpandedFinish(null);
    updateAmount(
      setAreaTables,
      setRateTables,
      tableId,
      areaId,
      itemId,
      subItemId,
      subIndex
    );
    updateItemAmount(setAreaTables, tableId, areaId, itemId);
    updateAreaAmount(setAreaTables, tableId, areaId);
    updateSubTotalAmount(setAreaTables, setSubTotalTables, subTotalTables);
  };

  const handleFinishTypeClick = (
    categoryId,
    finishId,
    typeId,
    category,
    finish,
    type,
    unit,
    range,
    description
  ) => {
    selectFinishType(
      categoryId,
      finishId,
      typeId,
      category,
      finish,
      type,
      unit,
      range,
      rates,
      description
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Function to filter finishes based on the search query
  const filterFinishes = (categories) => {
    if (!searchTerm) return categories;

    return categories.filter((category) =>
      category.finishes.some((finish) =>
        finish.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  return (
    <section className="finishes-section">
      <input
        type="text"
        placeholder="Search Finishes and Types"
        className="Helo"
        onChange={handleSearchChange}
      />
      <div className="finishes-list">
        {finishesData &&
          finishesData.length > 0 &&
          finishesData[0].categories &&
          finishesData[0].categories.length > 0 &&
          (filterFinishes(finishesData[0].categories).length > 0 ? (
            filterFinishes(finishesData[0].categories).map(
              (category, categoryIndex) => (
                <div key={categoryIndex} className="finishes-item">
                  <h2
                    className="container-title"
                    style={{
                      background: `linear-gradient(to right,  ${lightenColor(
                        table_header,
                        0
                      )}, ${lightenColor(table_header, 30)} , ${lightenColor(
                        table_header,
                        95
                      )})`,
                      color: table_font,
                    }}
                  >
                    {category.name}
                  </h2>
                  {category.finishes &&
                    category.finishes.length > 0 &&
                    category.finishes.map((finish, finishIndex) => (
                      <div key={finishIndex}>
                        <h3
                          className="finishes-title"
                          onClick={() =>
                            toggleFinish(`${categoryIndex}-${finishIndex}`)
                          }
                        >
                          {finish.name}
                        </h3>
                        {expandedFinish ===
                          `${categoryIndex}-${finishIndex}` && (
                          <ul className="finish-types" ref={wrapperRef}>
                            {finish.types &&
                              finish.types.length > 0 &&
                              finish.types.map((type, typeIndex) => (
                                <li
                                  key={typeIndex}
                                  className="finish-type-item"
                                >
                                  <h4
                                    className="finish-type-title"
                                    onClick={() =>
                                      handleFinishTypeClick(
                                        category._id,
                                        finish._id,
                                        type._id,
                                        category.name,
                                        finish.name,
                                        type.name,
                                        type.unit,
                                        type.range,
                                        type.description
                                      )
                                    }
                                    onMouseEnter={() =>
                                      setHoveredType(
                                        `${categoryIndex}-${finishIndex}-${typeIndex}`
                                      )
                                    }
                                    onMouseLeave={() => setHoveredType(null)}
                                  >
                                    {type.name}
                                  </h4>
                                  {hoveredType ===
                                    `${categoryIndex}-${finishIndex}-${typeIndex}` &&
                                    type.description && (
                                      <p className="finish-type-title-description">
                                        {type.description}
                                      </p>
                                    )}
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>
                    ))}
                </div>
              )
            )
          ) : (
            <p>No finishes available.</p>
          ))}

        <div className="to-select-Others">
          <span
            className="to-select-other-word"
            onClick={() =>
              handleFinishTypeClick("", "", "","Other", "", "", "Other", "Other")
            }
          >
            Others
          </span>
          <span className="to-select-other-info">
            Need a different finish/type? Select "Others" to customize your
            finish/type with a lump sum rate.
          </span>
        </div>
      </div>
    </section>
  );
}

export default FinishesTypes;
