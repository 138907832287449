import React from "react";

function TermsAndConditions() {
  return (
    // using privacy policy css

    <article className="privacy-policy-container">
      <h1 className="privacy-policy-title">Terms and Conditions :</h1>

      <section className="privacy-policy-content">
        <ol className="privacy-policy-list">
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Material Specification</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Interioverse utilizes high-quality materials, including IS
                marked plywood for core construction, offering durability and
                strength.
              </li>
              <li className="privacy-policy-detail">
                Laminates, hardware, Tiles, Paints and other products are
                selected from a comprehensive catalog, ensuring a wide range of
                finishes and options.{" "}
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">
              GST and Quotation Validity
            </h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Applicable GST rate is 18% across all category.
              </li>
              <li className="privacy-policy-detail">
                Rates quoted are valid for three months from the date of
                issuance.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Other Terms</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Clients are responsible for post-work painting and minor damages
                to property.
              </li>
              <li className="privacy-policy-detail">
                Interioverse is not liable for loss or theft during installation
                and requires client cooperation for access and permissions.
              </li>
              <li className="privacy-policy-detail">
                {" "}
                The company reserves the right to photograph completed work for
                internal records and commercial purposes.
              </li>
              <li className="privacy-policy-detail">
                Material specifications outlined in the quotation supersede any
                other specifications.
              </li>
              <li className="privacy-policy-detail">
                {" "}
                Legal matters are subject to Bangalore's jurisdiction.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Delay</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Interioverse strives for timely project completion and
                communicates timelines based on technologically assisted project
                management tools.{" "}
              </li>
            </ul>
          </li>
        </ol>
        <p className="privacy-policy-consent">
          By engaging with Interioverse, you agree to adhere to these payment
          terms and refund policies. For any queries or clarifications, please
          contact us at enquiry@interioverse.com.
        </p>
      </section>
    </article>
  );
}

export default TermsAndConditions;
