import { useEffect, useState } from 'react';

const useScreenOrientation = () => {
  const [isLandscape, setIsLandscape] = useState(window.innerWidth < 950);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth < 950);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set the initial state

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isLandscape;
};

export default useScreenOrientation;
