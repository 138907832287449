// src/redux/reducers/signupReducer.js
import { SET_LOGIN, SET_SIGNUP } from '../actions/refreshToken.action';

const initialState = {
  isOpen: false, 
  isLogin:false // Default state is false
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIGNUP:
      return {
        ...state,
        isOpen: action.payload,
      };
      case SET_LOGIN:
      return {
        ...state,
        isLogin: action.payload,
      };
    default:
      return state;
  }
};

export default signupReducer;
