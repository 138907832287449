import React, { useEffect, useState } from "react";
import "./signupPage.css";
import { GoogleLogin } from "@react-oauth/google";
import { IoMdClose } from "react-icons/io";
import AXIOS from "../../services/axios";
import { setSignup } from "../../services/redux/actions/refreshToken.action";
import { useDispatch } from "react-redux";

function Signup({ setDropDown, dropDown, signupData,setSignupData }) {
  const [mailError, setMailError] = useState(false); //state to check email is already exist or not
  const dispatch = useDispatch();

  // function to capture user inpute value in formdata state variable
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupData((prevSignupData) => ({
      ...prevSignupData,
      [name]: name === "phone" ? value.replace(/\D/g, "") : value,
    }));
  };

  //function to continue to next step
  const handleContinueBtn = async (e) => {
    e.preventDefault();

    try {
      const response = await AXIOS.post("/user/verify-email", {
        name: signupData.name,
        email: signupData.email,
      });

      if (response.status === 200) {
        setDropDown({
          ...dropDown,
          signUp: false,
          login: false,
          otp: true,
          mobileInput: true
        });
          dispatch(setSignup(false));
          
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setMailError(true);
        }
      } else {
        console.error("Error verifying email:", error);
        alert(
          "An error occurred while verifying email. Please try again later."
        );
      }
    }
  };

  //to make mailError false when entering mail-id
  useEffect(() => {
    setMailError(false);
  }, [signupData.email]);

  const handleGoogleLogin = async (response) => {
    try {
      const tokenId = response.credential; // Get token ID from Google response
      const { data } = await AXIOS.post("/user/google-signup", { tokenId });
      const { name, email } = data;
      setSignupData({ name, email });
    } catch (error) {
      console.error("Error logging in with Google:", error);
    }
  };

  return (
    <section className="signup-page">
      <article className="signup-page-content">
        <IoMdClose
          className="signup-close-icon"
          onClick={() => {
            setDropDown({
              ...dropDown,
              signUp: false,
              login: false,
              profile: false,
            });
            setSignupData({name:"",email:""})
            dispatch(setSignup(false));
          }}
        />
        <h1 className="signup-header">Create Your Account Now</h1>

        <form className="signup-form" onSubmit={handleContinueBtn}>
          <div className="signup-form-group">
            <label htmlFor="full-name">Full Name</label>
            <input
              type="text"
              id="full-name"
              placeholder="Your full name"
              required
              name="name"
              value={signupData.name}
              onChange={handleChange}
              className="form-input "
            />
          </div>

          <div className="signup-form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Your email address"
              required
              name="email"
              value={signupData.email}
              onChange={handleChange}
              className={`form-input ${mailError ? "input-error" : ""}`}
            />
            {mailError && <p className="error">Email ID already exist</p>}
          </div>

          <div className="signup-button">
            <button type="submit">Continue</button>
          </div>
        </form>

        <div className="signup-separator">Or</div>

        <div className="signup-google">
          <GoogleLogin
            onSuccess={(response) => {
              handleGoogleLogin(response); // Call your function to handle Google login here
            }}
            onError={(error) => console.log("Google login error:", error)}
          />
        </div>

        <p className="signup-login-line">
          Have an account?{" "}
          <a
            onClick={() =>
              setDropDown({ ...dropDown, signUp: false, login: true })
            }
          >
            Login
          </a>
        </p>
      </article>
    </section>
  );
}

export default Signup;
