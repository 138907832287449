// App.js
import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, useLocation } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import RoutesWithScrollTop from "./services/routesWithScrollTop"; // Import your new component
import { useDispatch } from "react-redux";
import { refreshToken } from "./services/redux/thunk/authThunk";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
 

  useEffect(() => {
    const checkAuth = async () => {
      const timeoutPromise = new Promise((resolve) => {
        setTimeout(resolve, 5); // 10 milliseconds
      });

      await Promise.all([timeoutPromise, dispatch(refreshToken())]);
      setLoading(false);
    };

    checkAuth();
  }, [dispatch]);

  // useEffect(() => {
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   const handleKeyDown = (e) => {
  //     if (e.key === 'F12' || 
  //         (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J')) ||
  //         (e.ctrlKey && e.key === 'U')) {
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener('contextmenu', handleContextMenu);
  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);


  return (
    <>
      <GoogleOAuthProvider clientId="298901644279-pu2fbe8phtdsrupu72cqko0vvlqrs6id.apps.googleusercontent.com">
        <BrowserRouter>
              <RoutesWithScrollTop loading={loading} />{" "}
              {/* this component includes routes ,route and scroll to top function */}
  
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
