import React, { useEffect, useRef, useState } from "react";
import landingPageImg from "../../../assets/Landing_page_picture.jpg";
import "./new-quotation.css";
import Sidebar from "../../../components/sidebar_newquotation/sideBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NewQuotationTable from "./new_quotation_table";
import AXIOS from "../../../services/axios";
import {
  generateTable,
  intialRateTable,
} from "../../../services/reusableCodes";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../../components/loading/loading";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import MobileScreenMessage from "../../../components/mobileScreenMessage/mobileScreenMessage";
import { FaPlus } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";

function NewQuotation() {
  const [color, setColor] = useState({
    background_color: "#fff7ef",
    border_color: "#fff7ef",
    table_header: "#f16f40",
    table_font: "#ffffff",
    table_border: "#f16f40",
    table_amount: "#ffffff",
    amount_font: "#636363",
  });
  const [selectedFontFamily, setSelectedFontFamily] = useState("Montserrat");
  const { pathname } = useLocation();
  const { projectId, version } = useParams();
  const initialTable = generateTable();
  const initialRateTable = intialRateTable(initialTable);
  const [areaTables, setAreaTables] = useState([initialTable]);
  const [rateTables, setRateTables] = useState([initialRateTable]);
  const [subTotalTables, setSubTotalTables] = useState({
    subTotal: "0",
    total: "0",
    taxableAmount: "0",
    gst: {
      name: "",
      percentage: "",
      amount: "0",
    },
    add: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
    minus: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
  });
  const [summary, setSummary] = useState({
    area: [
      {
        name: "",
        value: "",
      },
    ],
    subtotal: "",
    total: "",
  });
  const [updateSave, setUpdateSave] = useState(false);
  const [interioverseRate, setInterioversRate] = useState({
    total: "",
    gstTotal: "",
    mainTotal: "",
    InterioverseGst: "",
    userRange: "",
    maxRange: "",
    designerCommision: "",
  });
  const [loading, setLoading] = useState(true);
  const [isCityFound, setIsCityFound] = useState(false);
  const [editorPages, setEditorPages] = useState([
    {
      id: uuidv4(),
      editorRef: React.createRef(),
      quillRef: React.createRef(),
      textBox: [],
    },
  ]);
  const [tablePages, setTablePages] = useState([]);
  const [initialPages, setInitialPages] = useState([]);
  const [initialBoxes, setInitialBoxes] = useState([]);
  const [pageContentData, setPageContentData] = useState({
    content: [],
    pageMerge: "",
  });
  const [activePage, setActivePage] = useState(null);
  const [activeBox, setActiveBox] = useState(null);
  const isMobile = useSelector((state) => state.isMobileScreen.isMobile);
  const [versionsState, setVersionsState] = useState({
    count: [],
    versionEditing: {
      id: null,
      name: "",
      newName: "",
    },
    isVersionDelete: null,
    isAddVersion: false,
    error: false,
    activeVersion: false,
  });
  const navigate = useNavigate();
  const [isWarning, setIsWarning] = useState(false);

  const handleGetTableData = async (version) => {
    try {
      let response;
      response = await AXIOS.get(
        `/user-template/template/${projectId}/${version}`
      );

      if (response && response.status === 200) {
        let templateData;
        let color;
        if (response.data.user_template) {
          templateData = response.data.user_template;
          color = response.data.color;
        } else {
          templateData = response.data.baseTemplates[0];
        }

        if (templateData) {
          let newSubTotalTables;

          newSubTotalTables = {
            subTotal: templateData.user_subTotal || templateData.subTotal,
            total: templateData.user_total || templateData.total,
            taxableAmount:
              templateData.user_taxableAmount || templateData.taxableAmount,
            gst: templateData.user_gst || templateData.gst,
            add: templateData.user_add || templateData.add,
            minus: templateData.user_minus || templateData.minus,
          };

          // Extract areaTables data from templateData
          const newAreaTables = (templateData.template || []).map((table) => ({
            id: table.id || uuidv4(),
            area: (table.areas || []).map((area) => ({
              id: area.area_id || uuidv4(),
              areaValue: area.name || "",
              amount: area.amount || "",
              items: (area.items || []).map((item) => ({
                id: item.item_id || uuidv4(),
                itemValue: item.name || "",
                descriptionValue: item.description || "",
                amount: item.amount || "",
                subItems: (item.subItems || []).map((subItem) => ({
                  id: subItem.name_id || uuidv4(),
                  subItemValue: subItem.name || "",
                  amount: subItem.amount || "",
                  category: subItem.category || "",
                  finish: subItem.finish || "",
                  type: subItem.type || "",
                  height: subItem.height || "",
                  width: subItem.width || "",
                  amount: subItem.amount || "",
                  unit: subItem.unit || "",
                  range: subItem.range || "",
                  rate: subItem.rate || "",
                  description: subItem.description || "",
                  categoryId: subItem.categoryId || "",
                  finishId: subItem.finishId || "",
                  typeId: subItem.typeId || "",
                  warning: subItem.warning || "",
                  interioverseRate : subItem.interioverseRate || false
                })),
              })),
            })),
          }));

          setColor({
            background_color:
              templateData.background_color ||
              color.background_color ||
              "#fff7ef",
            table_header:
              templateData.table_header || color.table_header || "#f16f40",
            table_font:
              templateData.table_font || color.table_font || "#ffffff",
            table_border:
              templateData.table_border || color.table_border || "#f16f40",
            table_amount:
              templateData.table_amount || color.table_amount || "#ffffff",
            amount_font:
              templateData.amount_font || color.amount_font || "#636363",
          });
          setSelectedFontFamily(
            templateData.font_family || color.font_family || "Montserrat"
          );
          // Update the states
          setSubTotalTables(newSubTotalTables);
          setAreaTables(newAreaTables);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("Error saving data", error);
      setLoading(false);
    }
  };

  const fetchVersionData = async () => {
    try {
      // Replace with your API endpoint to fetch versions data
      const response = await AXIOS.get(
        `/user-template/get-version-names/${projectId}`
      );

      if (response.status === 200) {
        const versionsData = response.data.versionNames;

        if (versionsData.length > 0) {
          const mappedVersions = versionsData.map((version) => ({
            id: uuidv4(), // Generate a unique ID for each version
            name: version,
            copy: "",
          }));

          // Update the versionsState with the fetched data
          setVersionsState((prevState) => ({
            ...prevState,
            count: mappedVersions,
            activeVersion: version, // Set the active version to the first version fetched
          }));
        } else {
          // Update the versionsState if no versions are fetched
          setVersionsState((prevState) => ({
            ...prevState,
            count: [
              ...prevState.count,
              {
                id: uuidv4(), // Generate a unique ID for each version
                name: "Version 1",
                copy: "",
              },
            ],
            activeVersion: "Version 1", // Set default active version
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching versions data:", error);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      handleGetTableData(version);
      fetchVersionData();
    } else {
      setLoading(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (initialPages) {
      multiInitializeEditors(initialPages);
    }
    if (initialBoxes) {
      multiInitializeTextBox(initialBoxes);
    }
  }, [initialPages, initialBoxes]);

  const multiInitializeEditors = (pages) => {
    pages.forEach((page) => {
      if (page.editorRef.current && !page.quillRef.current) {
        const quill = new Quill(page.editorRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `#toolbar-${page.id}`,
              handlers: {
                customTextBox: () =>
                  quill.getModule("customTextBox").showTextBox(),
              },
            },
            customTextBox: {
              showTextBox: (range) => {
                const bounds = quill.getBounds(range);
              },
            },
          },
        });

        page.editorRef.current.setAttribute("spellinitialPages", "false");
        page.quillRef.current = quill;
      }
    });
  };

  const multiInitializeTextBox = (boxes) => {
    boxes.forEach((box) => {
      if (box.textBoxRef.current && !box.quillRef.current) {
        const Font = Quill.import("formats/font");
        Font.whitelist = ["mirza", "roboto", "serif"];
        Quill.register(Font, true);

        const quill = new Quill(box.textBoxRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `#textBox-${box.id}`,
            },
          },
        });

        box.textBoxRef.current.setAttribute("spellinitialPages", "false");
        box.quillRef.current = quill;
      }
    });
  };

  useEffect(() => {
    const tableData = async () => {
      try {
        if (
          pageContentData.content &&
          Array.isArray(pageContentData.content) &&
          pageContentData.content.length > 0
        ) {
          const pagesContent = pageContentData.content;
          const pageToMerge = parseInt(pageContentData.pageMerge, 10);

          // Update editorPages
          editorPages.forEach((prevPage) => {
            const matchedPage = pagesContent
              .slice(0, pageToMerge)
              .find((page) => page.id === prevPage.id);
            if (matchedPage) {
              if (prevPage.quillRef.current) {
                prevPage.quillRef.current.root.innerHTML =
                  matchedPage.quillData;
              }

              prevPage.textBox.forEach((prevBox) => {
                const matchedBox = matchedPage.textbox.find(
                  (box) => box.id === prevBox.id
                );
                if (matchedBox) {
                  if (prevBox.quillRef.current) {
                    prevBox.quillRef.current.root.innerHTML =
                      matchedBox.quillData;
                  }
                }
              });
            }
          });

          // Update tablePages
          tablePages.forEach((prevPage) => {
            const matchedPage = pagesContent
              .slice(pageToMerge)
              .find((page) => page.id === prevPage.id);
            if (matchedPage) {
              if (prevPage.quillRef.current) {
                prevPage.quillRef.current.root.innerHTML =
                  matchedPage.quillData;
              }

              prevPage.textBox.forEach((prevBox) => {
                const matchedBox = matchedPage.textbox.find(
                  (box) => box.id === prevBox.id
                );
                if (matchedBox) {
                  if (prevBox.quillRef.current) {
                    prevBox.quillRef.current.root.innerHTML =
                      matchedBox.quillData;
                  }
                }
              });
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    tableData();
  }, [initialPages, initialBoxes]);

  useEffect(() => {
    if (activePage) {
      initializeEditors(activePage);
    }
  }, [activePage]);

  useEffect(() => {
    const newSummary = {
      area: areaTables.flatMap((table) =>
        (table.area || []).map((area) => ({
          name: area.areaValue || "",
          value: area.amount || "",
        }))
      ),
      subtotal: subTotalTables.subTotal || "0",
      total: subTotalTables.total || "0",
    };

    // Update the summary state
    setSummary(newSummary);
  }, [areaTables, rateTables]);

  const textBoxImage = (boxId, width, height) => {
    // Helper function to find and update the page containing the boxId
    const findAndUpdateBoxInPages = (pages, setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) => {
          if (page.textBox.some((box) => box.id === boxId)) {
            return {
              ...page,
              textBox: page.textBox.map((box) =>
                box.id === boxId
                  ? { ...box, image: true, width: width, height: height }
                  : box
              ),
            };
          }
          return page;
        })
      );
    };

    // initialPages in editorPages and update if found
    const pageExistsInEditorPages = editorPages.some((page) =>
      page.textBox.some((box) => box.id === boxId)
    );
    if (pageExistsInEditorPages) {
      findAndUpdateBoxInPages(editorPages, setEditorPages);
      return;
    }

    // initialPages in tablePages and update if found
    const pageExistsInTablePages = tablePages.some((page) =>
      page.textBox.some((box) => box.id === boxId)
    );
    if (pageExistsInTablePages) {
      findAndUpdateBoxInPages(tablePages, setTablePages);
    }
  };

  const initializeEditors = (page) => {
    if (page.editorRef.current && !page.quillRef.current) {
      const Font = Quill.import("formats/font");
      Font.whitelist = ["mirza", "roboto", "serif"];
      Quill.register(Font, true);

      const Size = Quill.import("attributors/style/size");
      Size.whitelist = ["10px", "13px", "18px", "24px", "32px", "48px"];
      Quill.register(Size, true);

      const quill = new Quill(page.editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: `#toolbar-${page.id}`,
            handlers: {
              bold: function () {
                const isBold = quill.getSelection() && quill.getFormat().bold;
                quill.format("bold", !isBold);
              },
              italic: function () {
                const isItalic =
                  quill.getSelection() && quill.getFormat().italic;
                quill.format("italic", !isItalic);
              },
              underline: function () {
                const isUnderline =
                  quill.getSelection() && quill.getFormat().underline;
                quill.format("underline", !isUnderline);
              },
              color: function (value) {
                if (quill.getSelection()) {
                  quill.format("color", value);
                  quill.format("background", false);
                }
              },
              background: function (value) {
                if (quill.getSelection()) {
                  quill.format("background", value);
                }
              },
              clean: function () {
                const selection = quill.getSelection();
                if (selection) {
                  quill.removeFormat(selection.index, selection.length);
                }
              },
              list: function (value) {
                const range = quill.getSelection();
                if (range) {
                  const format = quill.getFormat(range);
                  if (format.list === value) {
                    quill.format("list", false);
                  } else {
                    quill.format("list", value);
                  }
                }
              },
              indent: function (value) {
                quill.format("indent", value);
              },
              align: function (value) {
                quill.format("align", value);
              },
              size: function (value) {
                const range = quill.getSelection();
                if (range) {
                  quill.format("size", value);
                }
              },
              font: function (value) {
                const currentSelection = quill.getSelection();
                if (currentSelection) {
                  const { index, length } = currentSelection;
                  quill.formatText(index, length, "font", value);
                  setTimeout(() => {
                    quill.setSelection(index, length);
                  }, 0);
                }
              },
              customTextBox: () =>
                quill.getModule("customTextBox").showTextBox(),
            },
          },
          customTextBox: {
            showTextBox: (range) => {
              const bounds = quill.getBounds(range);
            },
          },
        },
      });

      page.editorRef.current.setAttribute("spellinitialPages", "false");
      page.quillRef.current = quill;
    }
  };

  const initializeTextBox = (box) => {
    if (box && box.textBoxRef.current && !box.quillRef.current) {
      const Font = Quill.import("formats/font");
      Font.whitelist = ["mirza", "roboto", "serif"];
      Quill.register(Font, true);

      const quill = new Quill(box.textBoxRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: `#textBox-${box.id}`,
            handlers: {
              bold: function () {
                const isBold = quill.getSelection() && quill.getFormat().bold;
                quill.format("bold", !isBold);
              },
              italic: function () {
                const isItalic =
                  quill.getSelection() && quill.getFormat().italic;
                quill.format("italic", !isItalic);
              },
              underline: function () {
                const isUnderline =
                  quill.getSelection() && quill.getFormat().underline;
                quill.format("underline", !isUnderline);
              },
              color: function (value) {
                if (box.image) return;
                if (quill.getSelection()) {
                  quill.format("color", value);
                  quill.format("background", false);
                }
              },
              background: function (value) {
                if (box.image) return;
                if (quill.getSelection()) {
                  quill.format("background", value);
                }
              },
              clean: function () {
                const selection = quill.getSelection();
                if (selection) {
                  quill.removeFormat(selection.index, selection.length);
                }
              },
              image: function () {
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");

                input.onchange = async (event) => {
                  const file = event.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const dataUrl = e.target.result;

                      // Create an image element to get the dimensions
                      const img = new Image();
                      img.onload = () => {
                        const width = img.width;
                        const height = img.height;

                        quill.focus();
                        const range = quill.getSelection();
                        quill.setContents([]); // Clear the content
                        quill.insertEmbed(range.index, "image", dataUrl);
                        quill.insertText(range.index + 1, "\u200B"); // Insert zero-width space
                        quill.setSelection(range.index + 1); // Set cursor to the zero-width space

                        if (box.image === false) {
                          textBoxImage(box.id, width, height);
                        }
                      };
                      img.src = dataUrl;
                    };
                    reader.readAsDataURL(file);
                  }
                };
                input.click();
              },
              list: function (value) {
                const range = quill.getSelection();
                if (range) {
                  const format = quill.getFormat(range);
                  if (format.list === value) {
                    quill.format("list", false);
                  } else {
                    quill.format("list", value);
                  }
                }
              },
              align: function (value) {
                quill.format("align", value);
              },
              font: function (value) {
                if (box.image) return;
                const currentSelection = quill.getSelection();
                if (currentSelection) {
                  const { index, length } = currentSelection;
                  quill.formatText(index, length, "font", value);
                  setTimeout(() => {
                    quill.setSelection(index, length);
                  }, 0);
                }
              },
            },
          },
        },
      });

      box.textBoxRef.current.setAttribute("spellinitialPages", "false");
      box.quillRef.current = quill;
    }
  };

  useEffect(() => {
    if (activeBox) {
      initializeTextBox(activeBox);
    }
  }, [activeBox]);

  useEffect(() => {
    const checkCity = async () => {
      try {
        const response = await AXIOS.get(`/project/check-city/${projectId}`);
        if (response.status === 200) {
          setIsCityFound(true);
        }
      } catch (error) {
        if (error.response.status === 200) {
          setIsCityFound(false);
        }
      }
    };

    if (projectId) {
      checkCity();
    }
  }, [pathname]);

  const handleAddVersion = () => {
    const newVersionName = document.querySelector(".version-input-field").value;

    if (!newVersionName) {
      setVersionsState((prevState) => ({
        ...prevState,
        error: "empty-name",
      }));
      return;
    }

    const isNameTaken = versionsState.count.some(
      (version) => version.name === newVersionName
    );

    if (isNameTaken) {
      setVersionsState((prevState) => ({
        ...prevState,
        error: "duplicate-name",
      }));
      return;
    }

    const selectedVersion = document.querySelector(
      ".version-list-item-selected"
    )?.textContent;

    const newVersion = {
      id: uuidv4(),
      name: newVersionName,
      copy: selectedVersion || "",
    };

    handleSaveVersion(newVersion);
  };

  const handleRemoveVersion = (id) => {
    setVersionsState((prevState) => {
      return {
        ...prevState,
        isVersionDelete: id,
      };
    });
  };

  const handleVersionDoubleClick = (id, name) => {
    setVersionsState({
      ...versionsState,
      versionEditing: {
        id: id,
        name: name,
      },
    });
  };

  const handleVersionName = (e, id) => {
    setVersionsState({
      ...versionsState,
      versionEditing: {
        ...versionsState.versionEditing,
        newName: e.target.value,
      },
      error: false,
    });
  };

  const versionToDelete = versionsState.isVersionDelete
    ? versionsState.count.find(
        (version) => version.id === versionsState.isVersionDelete
      )
    : null;

  const handleSaveVersion = async (newVersion) => {
    try {
      const response = await AXIOS.post(`/user-template/save-version`, {
        projectId: projectId,
        version: newVersion.name,
        copyVersion: newVersion.copy,
      });
      if (response.status === 200) {
        handleGetTableData(newVersion.name);
        setVersionsState((prevState) => ({
          ...prevState,
          count: [...prevState.count, newVersion],
          isAddVersion: false,
          activeVersion: newVersion.name,
        }));
        navigate(`/new-project/${projectId}/${newVersion.name}`);
      }
    } catch (error) {
      console.log("Error saving data", error);
    }
  };

  const handleActiveVersion = (name) => {
    handleGetTableData(name);

    setVersionsState({
      ...versionsState,
      activeVersion: name,
    });
    navigate(`/new-project/${projectId}/${name}`);
  };

  const handleDeleteVersion = async (id, name) => {
    try {
      const response = await AXIOS.delete(
        `/user-template/delete-version/${projectId}/${name}`
      );
      if (response.status === 200) {
        // Determine the index of the version to be deleted
        const versionIndex = versionsState.count.findIndex(
          (version) => version.name === name
        );

        const prevVersionIndex = versionIndex > 0 ? versionIndex - 1 : null;
        const previousVersion =
          prevVersionIndex !== null
            ? versionsState.count[prevVersionIndex]
            : null;

        setVersionsState((prevState) => ({
          ...prevState,
          isVersionDelete: null,
          count: prevState.count.filter((version) => version.id !== id),
        }));

        if (previousVersion.name) {
          handleActiveVersion(previousVersion.name);
        }
      }
    } catch (error) {
      console.error("Error deleting version:", error);
      setVersionsState((prevState) => ({ ...prevState, error: true }));
    }
  };

  const handleUpdateVersionName = async () => {
    const { id, name: oldName, newName } = versionsState.versionEditing;

    // Find the old version name in the count array
    const versionToUpdate = versionsState.count.find(
      (version) => version.id === id
    );

    if (!versionToUpdate) {
      console.error("Version not found");
      return;
    }

    const isNameTaken = versionsState.count.some(
      (version) => version.name === newName && version.id !== versionToUpdate.id
    );

    if (isNameTaken && newName) {
      setVersionsState((prevState) => ({
        ...prevState,
        error: "new-name",
      }));
      return;
    }

    try {
      // Send update request to backend
      const response = await AXIOS.put("/user-template/update-version-name", {
        projectId: projectId, // Replace with your actual project ID
        oldVersionName: oldName,
        newVersionName: newName,
      });

      if (response.status === 200) {
        // Update the version in the local state
        setVersionsState((prevState) => ({
          ...prevState,
          count: prevState.count.map((version) =>
            version.id === id ? { ...version, name: newName } : version
          ),
          versionEditing: {
            id: null,
            name: "",
            newName: "",
          },
          error: false,
        }));
        navigate(`/new-project/${projectId}/${newName}`);
      }
    } catch (error) {
      console.error("Error updating version name:", error);
      setVersionsState((prevState) => ({
        ...prevState,
        error: "empty-name",
      }));
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (isMobile) {
    return <MobileScreenMessage />;
  }

  return (
    <div>
      <figure>
        <img
          src={landingPageImg}
          alt="Cover img"
          className="partner-cover-img"
        />
      </figure>

      {/* //version delete popup */}
      {versionToDelete && (
        <section className="version-delete-popup">
          <div className="version-delete-container">
            <div className="version-delete-content">
              <p className="version-delete-label">{`Do you want to delete ${versionToDelete.name} quotation?`}</p>
              <div className="version-button-group">
                <button
                  className="version-delete-button"
                  onClick={() =>
                    handleDeleteVersion(
                      versionToDelete.id,
                      versionToDelete.name
                    )
                  }
                >
                  Delete
                </button>
                <button
                  className="version-cancel-button"
                  onClick={() =>
                    setVersionsState({
                      ...versionsState,
                      isVersionDelete: null,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* //version add popup */}
      {versionsState.isAddVersion && (
        <section className="version-delete-popup">
          <div className="version-delete-container">
            <div className="version-delete-content">
              <div className="version-input-container">
                <label className="version-input-label">
                  Enter version name
                </label>
                <input
                  type="text"
                  className="version-input-field"
                  placeholder="version name"
                  required
                  onChange={() =>
                    setVersionsState({
                      ...versionsState,
                      error: false,
                    })
                  }
                />
                {versionsState.error === "empty-name" && (
                  <p className="error version-error">
                    Please enter version name
                  </p>
                )}

                {versionsState.error === "duplicate-name" && (
                  <p className="error version-error">
                    Version name already exists
                  </p>
                )}
              </div>

              {versionsState.count.length > 1 && (
                <div className="version-list-container">
                  <p className="version-list-title">
                    Which version would you like to copy
                  </p>
                  <div className="version-list-items">
                    {versionsState.count.map((version, index) => (
                      <p
                        key={index}
                        className="version-list-item"
                        onClick={() =>
                          document
                            .querySelectorAll(".version-list-item")
                            .forEach((btn) =>
                              btn.classList.remove("version-list-item-selected")
                            ) ||
                          document
                            .querySelectorAll(".version-list-item")
                            [index].classList.add("version-list-item-selected")
                        }
                      >
                        {version.name}
                      </p>
                    ))}
                  </div>
                </div>
              )}

              <div className="version-button-group">
                <button
                  className="version-delete-button"
                  onClick={handleAddVersion}
                >
                  Create
                </button>
                <button
                  className="version-cancel-button"
                  onClick={() =>
                    setVersionsState({
                      ...versionsState,
                      isAddVersion: false,
                      error: false,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* //version edit popup */}
      {versionsState.versionEditing.id && (
        <section className="version-delete-popup">
          <div className="version-delete-container">
            <div className="version-delete-content">
              <div className="version-input-container">
                <label className="version-input-label">
                  Enter a new name for the version
                </label>
                <input
                  type="text"
                  className="version-input-field"
                  placeholder="Update version name"
                  required
                  onChange={(e) =>
                    handleVersionName(e, versionsState.versionEditing.id)
                  }
                />
                {versionsState.error === "new-name" && (
                  <p className="error version-error">
                    New version name already exists
                  </p>
                )}
                {versionsState.error === "empty-name" && (
                  <p className="error version-error">
                    Please enter a new name for the version
                  </p>
                )}
              </div>

              <div className="version-button-group">
                <button
                  className="version-delete-button"
                  onClick={handleUpdateVersionName}
                >
                  Udpate
                </button>
                <button
                  className="version-cancel-button"
                  onClick={() =>
                    setVersionsState({
                      ...versionsState,
                      versionEditing: {
                        id: null,
                        name: "",
                        newName: "",
                      },
                      error: false,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="quotation-template">
        <article className="quotation-template-container">
          {/* versions */}
          <div className="version-container">
            {versionsState.count.map((version) => (
              <div
                key={version.id}
                className="version-item"
                style={{
                  backgroundColor:
                    version.name === versionsState.activeVersion
                      ? color.table_header
                      : color.background_color,
                  color:
                    version.name === versionsState.activeVersion
                      ? color.table_font
                      : "#636363",
                }}
              >
                <span
                  onDoubleClick={() =>
                    handleVersionDoubleClick(version.id, version.name)
                  }
                  onClick={() => handleActiveVersion(version.name)}
                >
                  {version.name}
                </span>

                <IoCloseOutline
                  className="version-icon version-remove-icon"
                  onClick={() =>
                    versionsState.count.length > 1 &&
                    handleRemoveVersion(version.id)
                  }
                />
              </div>
            ))}
            {versionsState.count.length < 10 && (
              <FaPlus
                className="version-icon version-add-icon"
                onClick={() =>
                  setVersionsState({
                    ...versionsState,
                    isAddVersion: true,
                  })
                }
              />
            )}
          </div>

          <section className="quotation-table">
            <NewQuotationTable
              areaTables={areaTables}
              setAreaTables={setAreaTables}
              rateTables={rateTables}
              setRateTables={setRateTables}
              subTotalTables={subTotalTables}
              setSubTotalTables={setSubTotalTables}
              background_color={color.background_color}
              table_header={color.table_header}
              table_font={color.table_font}
              table_border={color.table_border}
              table_amount={color.table_amount}
              amount_font={color.amount_font}
              selectedFontFamily={selectedFontFamily}
              projectId={projectId}
              isCityFound={isCityFound}
            />
          </section>
        </article>
        <Sidebar
          interioverseRate={interioverseRate}
          summary={summary}
          updateSave={updateSave}
          areaTables={areaTables}
          subTotalTables={subTotalTables}
          setUpdateSave={setUpdateSave}
          color={color}
          font_family={selectedFontFamily}
          projectId={projectId}
          version={version}
          isCityFound={isCityFound}
          maxRange={interioverseRate.maxRange}
          isWarning={isWarning}
          setIsWarning={setIsWarning}
        />
      </section>
    </div>
  );
}

export default NewQuotation;
