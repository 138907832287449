import React from "react";

function ShippingDeliveryTerms() {
  return (
    // using privacy policy css 
    <article className="privacy-policy-container">
      <h1 className="privacy-policy-title">Shipping and Delivery Terms</h1>
      <section className="privacy-policy-content">
        <ol className="privacy-policy-list">
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Shipping Methods</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Interioverse utilizes reliable shipping methods to deliver
                products to customers.
              </li>
              <li className="privacy-policy-detail">
                Depending on the item's size, weight, and destination, shipping
                may be fulfilled through courier services, freight carriers, or
                direct delivery from manufacturers.{" "}
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Delivery Timeframes</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Estimated delivery times vary based on product availability,
                manufacturing lead times, and shipping distances.
              </li>
              <li className="privacy-policy-detail">
                {" "}
                Customers will be provided with estimated delivery dates upon
                order confirmation.{" "}
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Delivery Address</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Customers must provide accurate delivery addresses during
                checkout to ensure successful delivery.
              </li>
              <li className="privacy-policy-detail">
                Any changes to the delivery address must be communicated to
                Interioverse promptly.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Delivery Charges</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Shipping charges are calculated based on the item's size,
                weight, and destination.
              </li>
              <li className="privacy-policy-detail">
                {" "}
                Customers will be informed of shipping charges during the
                checkout process.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Delivery Tracking</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Tracking information will be provided to customers once the
                order is shipped.
              </li>
              <li className="privacy-policy-detail">
                Customers can track their orders in real-time through the
                provided tracking number or link.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Delivery Inspection</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                {" "}
                Upon delivery, customers are advised to inspect the package for
                any signs of damage or tampering.
              </li>
              <li className="privacy-policy-detail">
                If any issues are discovered, customers should notify
                Interioverse immediately for resolution.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Delivery Acceptance</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                {" "}
                Customers or authorized representatives must be present to
                accept the delivery.
              </li>
              <li className="privacy-policy-detail">
                {" "}
                A signature may be required as proof of delivery.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Delivery Delays</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                While Interioverse endeavors to meet estimated delivery dates,
                unforeseen circumstances such as weather conditions or
                logistical challenges may cause delays.
              </li>
              <li className="privacy-policy-detail">
                {" "}
                In the event of a delay, Interioverse will communicate updates
                to customers promptly.
              </li>
            </ul>
          </li>

          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Delivery Installation</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                {" "}
                Some products may require professional installation, which can
                be arranged by Interioverse for an additional fee.{" "}
              </li>
              <li className="privacy-policy-detail">
                {" "}
                Customers are responsible for ensuring that installation
                requirements are met and for any associated installation costs.
              </li>
            </ul>
          </li>
          <li className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">International Shipping</h2>
            <ul className="privacy-policy-details">
              <li className="privacy-policy-detail">
                Interioverse offers international shipping for select products.
              </li>
              <li className="privacy-policy-detail">
                International customers are responsible for any applicable
                customs duties, taxes, or import fees.
              </li>
            </ul>
          </li>
        </ol>
        <p className="privacy-policy-consent">
          By placing an order with Interioverse, you agree to adhere to these
          shipping and delivery terms. For any questions or assistance regarding
          shipping, please contact our customer service team at
          enquiry@interioverse.com.
        </p>
      </section>
    </article>
  );
}

export default ShippingDeliveryTerms;
