import React, { useEffect, useState, useRef } from "react";
import "./login.css";
import { GoogleLogin } from "@react-oauth/google";
import { IoMdClose } from "react-icons/io";
import { resendOtpTimer } from "../../services/reusableCodes";
import AXIOS from "../../services/axios";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "../../services/redux/thunk/authThunk";
import { setLogin } from "../../services/redux/actions/refreshToken.action";

function Login({ setDropDown, dropDown, loginData, setLoginData }) {
  const [phoneError, setPhoneError] = useState({
    valid: false,
    exist: false,
    otpInvalid: false,
    invalidMail: false,
  });
  const [hideGetOtp, setHideGetOtp] = useState(false);
  const [timer, setTimer] = useState(30);
  const dispatch = useDispatch();
  const clearTimerRef = useRef(null);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      if (clearTimerRef.current) clearTimerRef.current(); // Stop the timer
      setTimer(30); // Reset the timer
      setHideGetOtp(false); // Show the "Get OTP" button again
    }
    setLoginData({ ...loginData, [name]: value.replace(/\D/g, "") });
  };

  //to make phone number false when entering phone number is valid or exist
  useEffect(() => {
    setPhoneError((prev) => ({
      ...prev,
      exist: false,
      valid: false,
      invalidMail: false,
    }));
  }, [loginData.phoneNumber]);

  useEffect(() => {
    setPhoneError((prev) => ({
      ...prev,
      otpInvalid: false,
      invalidMail: false,
    }));
  }, [loginData.otp]);

  // Handler to request OTP
  const handleGetOtp = async () => {
    try {
      if (loginData.phoneNumber.length < 10) {
        setPhoneError({ ...phoneError, valid: true });
        return;
      }

      const response = await AXIOS.post("/user/login", {
        phone_number: loginData.phoneNumber,
      });
      if (response.status == 200) {
        setHideGetOtp(true);
        clearTimerRef.current = resendOtpTimer(setTimer, 30);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setPhoneError({ ...phoneError, exist: true });
        }
      } else {
        console.error("Error requesting OTP:", error);
        alert("Failed to send OTP. Please try again.");
      }
    }
  };

  // Handler to verify OTP
  const handleVerifyOtp = async (e) => {
    e.preventDefault(); // prevent the form from reloading the page
    try {
      const response = await AXIOS.post("/user/verify-otp-login", {
        phone_number: loginData.phoneNumber,
        otp: loginData.otp,
      });
      if (response.status === 200) {
        dispatch(refreshToken());
        setLoginData({
          phoneNumber: "",
          otp: "",
        });
        setDropDown({
          ...dropDown,
          signUp: false,
          login: false,
          otp: false,
          profile: false,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setPhoneError((prev) => ({ ...prev, otpInvalid: true }));
      }
    }
  };

  const googleLoginApi = async (name, email) => {
    try {
      const response = await AXIOS.post("/user/google-login", {
        name: name,
        email: email,
      });
      if (response.status === 200) {
        dispatch(refreshToken());
        setLoginData({
          phoneNumber: "",
          otp: "",
        });
        setDropDown({
          ...dropDown,
          signUp: false,
          login: false,
          otp: false,
          profile: false,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setPhoneError((prev) => ({ ...prev, invalidMail: true }));
      }
    }
  };

  const handleGoogleLogin = async (response) => {
    setPhoneError((prev) => ({ ...prev, invalidMail: false }));
    try {
      const tokenId = response.credential; // Get token ID from Google response
      const { data } = await AXIOS.post("/user/google-signup", { tokenId });
      const { name, email } = data;
      if (name && email) {
        googleLoginApi(name, email);
        dispatch(setLogin(false))
      }
    } catch (error) {
      console.error("Error logging in with Google:", error);
    }
  };

  return (
    <section className="login-page">
      <article className="signup-page-content login-page-content">
        <IoMdClose
          className="signup-close-icon"
          onClick={() => {
            setDropDown({ ...dropDown, signUp: false, login: false });
            dispatch(setLogin(false));
            setLoginData({name:"", email:""})
          }}
        />
        <h1 className="signup-header">Log in to account</h1>

        <form className="signup-form" onSubmit={handleVerifyOtp}>
          <div className="signup-form-group">
            <label htmlFor="phone">Phone Number</label>
            <div className="login-input-section">
              <input
                type="text"
                id="phone"
                placeholder="Enter Phone Number"
                className={`form-input login-input ${
                  phoneError.exist || phoneError.valid ? "input-error" : ""
                }`}
                maxLength={10}
                value={loginData.phoneNumber}
                name="phoneNumber"
                onChange={handleInput}
                required
              />
              {!hideGetOtp && !phoneError.exist && !phoneError.valid && (
                <p className="otp" onClick={handleGetOtp}>
                  Get OTP
                </p>
              )}
              {hideGetOtp && timer !== 30 && (
                <p className="otp" onClick={timer === 0 ? handleGetOtp : null}>
                  {timer === 0 ? "Resend OTP" : `${timer} seconds`}
                </p>
              )}
            </div>
            {(phoneError.exist || phoneError.valid) && (
              <p className="error">
                {phoneError.exist
                  ? "Mobile number is not registered"
                  : "Mobile number must be 10 digits"}
              </p>
            )}
          </div>

          <div className="signup-form-group">
            <label htmlFor="otp">Enter OTP</label>
            <div className="login-input-section">
              <input
                type="text"
                id="otp"
                placeholder="Enter OTP"
                className={`form-input login-input ${
                  phoneError.otpInvalid ? "input-error" : ""
                }`}
                value={loginData.otp}
                name="otp"
                onChange={handleInput}
                required
              />
              {phoneError.otpInvalid && (
                <p className="error">Enter valid OTP</p>
              )}
            </div>
          </div>

          <div className="signup-button">
            <button type="submit">Login</button>
          </div>
        </form>

        <div className="signup-separator">Or</div>

        <div className="signup-google">
          <GoogleLogin
            onSuccess={(response) => {
              handleGoogleLogin(response); // Call your function to handle Google login here
            }}
            onError={(error) => console.log("Google login error:", error)}
          />

          {phoneError.invalidMail && (
            <p className="error google-error">User is not registered</p>
          )}
        </div>

        <p className="signup-login-line">
          Don't have an account?{" "}
          <a
            onClick={() =>
              setDropDown({
                ...dropDown,
                signUp: true,
                login: false,
              })
            }
          >
            Signup
          </a>
        </p>
      </article>
    </section>
  );
}

export default Login;
