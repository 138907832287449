import React, { useEffect, useState } from "react";
import landingPageImg from "../../../assets/Landing_page_picture.jpg";
import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import AXIOS from "../../../services/axios";
import { useSelector } from "react-redux";
import "./unity-landing-page.css";
import { MdDelete, MdEdit } from "react-icons/md";
import quotationImg from "../../../assets/quotation-img.jpg";
import promptIcon from "../../../assets/AIPromptIcon.png";
import A1 from "../../../assets/profile_avatars/A1.png";
import A2 from "../../../assets/profile_avatars/A2.png";
import A3 from "../../../assets/profile_avatars/A3.png";
import A4 from "../../../assets/profile_avatars/A4.png";
import A5 from "../../../assets/profile_avatars/A5.png";
import A6 from "../../../assets/profile_avatars/A6.png";
import s1 from "../../../assets/sampleUnityPics/s1.jpg";
import s2 from "../../../assets/sampleUnityPics/s2.jpg";
import s3 from "../../../assets/sampleUnityPics/s3.jpg";
import s4 from "../../../assets/sampleUnityPics/s4.jpg";
import s5 from "../../../assets/sampleUnityPics/s5.jpg";
import s6 from "../../../assets/sampleUnityPics/s6.jpg";
import s7 from "../../../assets/sampleUnityPics/s7.jpg";
import s8 from "../../../assets/sampleUnityPics/s8.jpg";
import s9 from "../../../assets/sampleUnityPics/s9.jpg";
import s10 from "../../../assets/sampleUnityPics/s10.jpg";
import s11 from "../../../assets/sampleUnityPics/s11.jpg";
import s12 from "../../../assets/sampleUnityPics/s12.jpg";
import f1 from "../../../assets/sampleUnityPics/f1.jpg";
import f2 from "../../../assets/sampleUnityPics/f2.jpg";
import f3 from "../../../assets/sampleUnityPics/f3.jpg";
import { IoIosSearch } from "react-icons/io";
import ModelPopup from "../../components/modelPopup/modelPopup";

function UnityLandingPage() {
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const [projects, setProjects] = useState("");
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const profileImg = useSelector((state) => state.auth.profileImg);
  const [itemsName, setItemsName] = useState("design");
  const imageMap = {
    A1: A1,
    A2: A2,
    A3: A3,
    A4: A4,
    A5: A5,
    A6: A6,
  };
  const designImages = [s3, s2, s1, s5, s4, s6, s7, s8, s9, s10, s2, s6];
  const modelImages = [f1, f2, f3, f1, f3, f2, f3, f1, f2, f1, f2, f3];

  const projectsData = async () => {
    try {
      const response = await AXIOS.get("/unity-project/get-unity-projects", {
        withCredentials: true,
      });
      if (response.status === 200) {
        setProjects(response.data.projects);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = async () => {
    try {
      // Make the API call to check or create the user
      const response = await AXIOS.post("/unity-project/check-furniture", {
        withCredentials: true, // Include credentials for authentication if necessary
      });

      // Check if the API call was successful
      if (response.data && response.data.success) {
        const userId = response.data.userId; // Get the userId from the response

        // Open the Unity app with the userId as parameter
        openUnityApp(userId);
      } else {
        console.error("Failed to retrieve userId from API response.");
      }
    } catch (error) {
      console.error("Error making the API request:", error);
    }
  };

  useEffect(() => {
    if (isAuth) {
      projectsData();
    }
  }, [isAuth]);

  const filteredProjects =
    projects &&
    projects.filter((project) =>
      project.project_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const displayedProjects = showAllProjects
    ? filteredProjects
    : filteredProjects.slice(0, 7);

  const openUnity2App = (projectId) => {
    // Encode projectId to Base64 for passing via query parameter
    const encodedData = btoa(JSON.stringify({ projectId }));

    // Construct the Unity WebGL URL with encoded projectId data
    const unityUrl = `${window.location.origin}/unity2/index.html?jsonData=${encodedData}`;
    window.open(unityUrl, "_blank");
  };

  const openUnityApp = (userId) => {
    const encodedData = btoa(JSON.stringify({ userId })); // Encode userId as Base64 string
    const unityUrl = `${window.location.origin}/unity1/index.html?jsonData=${encodedData}`; // Construct Unity app URL
    window.open(unityUrl, "_blank"); // Open Unity app in a new tab
  };

  const scrollToPrompt = () => {
    const promptElement = document.getElementById("ai-prompt-content");
    promptElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <article className="landing-page-container">
      {/* <ModelPopup /> */}
      {/* cover page section */}
      <div>
        <img
          src={landingPageImg}
          alt=""
          className={`landing-page-cover-img ${
            isAuth ? "signup-landing-page-cover-img" : ""
          }`}
        />
      </div>

      <section
        className={`landing-page-cover-content ${
          isAuth ? "unauth-landing-page-cover-content " : ""
        }`}
      >
        {isAuth && (
          <section className="landing-page-cover-heading">
            <h1>The Virtual world for interior designing </h1>
            <p>Transforming your World into Stunning Realities</p>
          </section>
        )}

        <section
          className={`landing-page-cover-quotation ${
            isAuth ? "signup-landing-page-cover-quotation" : ""
          }`}
        >
          <div className="unity-button-container">
            <button
              className="unity-explore-design-button"
              onClick={scrollToPrompt}
            >
              Explore Design Ideas
            </button>
          </div>
          <div className="unity-button-group">
            <button
              className="unity-designer-street-button"
              onClick={() =>
                window.open(`https://interioverse.glitch.me/`, "_blank")
              }
            >
              <span className="unity-designer-street-label">
                Designer Street (Metaverse)
              </span>
              <span className="unity-hire-designer-label">Hire a Designer</span>
            </button>
            <button
              className="unity-designing-tool-button"
              onClick={handleButtonClick}
            >
              Designing Tool
            </button>
          </div>
        </section>
      </section>

      <div
        className={`landing-page-content ${
          isAuth ? "auth-landing-page-content" : ""
        }`}
      >
        {/* quotation list */}
        {isAuth && (
          <>
            <section className="quotation-section">
              <section className="quotation-content">
                <article
                  className="quotation-item new-quotation"
                  onClick={() => navigate("/unity-create-project")}
                >
                  <div className="add-new" aria-label="Add New Quotation">
                    <GoPlus className="add-new-icon" />
                  </div>
                  <p>New Project</p>
                </article>

                {projects &&
                  projects.map((project, index) => (
                    <article
                      className="quotation-item existing-quotation"
                      key={index}
                    >
                      <figure onClick={() => openUnity2App(project.project_id)}>
                        <img
                          src={quotationImg}
                          alt="Quotation for Interior Design"
                          className="quotation-image"
                        />
                        <figcaption className="quotation-info">
                          <p className="quotation-info-line">
                            Quotation for Interior Design
                          </p>
                          <p className="quotation-name">
                            {project.project_name}
                          </p>
                        </figcaption>
                      </figure>
                    </article>
                  ))}
              </section>
            </section>

            <section className="ai-prompt-section" id="ai-prompt-content">
              <div className="ai-prompt-icon-container">
                <img
                  src={promptIcon}
                  alt="AI Icon"
                  className="ai-prompt-icon"
                />
              </div>
              <div className="ai-prompt-content">
                <h2 className="ai-prompt-title">AI Prompt</h2>
                <textarea
                  name="prompt"
                  placeholder="Describe the design idea you want to explore"
                  className="ai-prompt-textarea"
                  rows={4}
                  cols={4}
                ></textarea>
              </div>
            </section>

            <section className="design-model-container">
              <nav className="screen-furniture-nav">
                <p
                  onClick={() => setItemsName("design")}
                  className={`design-model-name ${
                    itemsName === "design" ? "selected-design-items" : ""
                  }`}
                >
                  Design
                </p>
                <p
                  onClick={() => setItemsName("model")}
                  className={`design-model-name ${
                    itemsName === "model" ? "selected-model-items" : ""
                  }`}
                >
                  Model
                </p>
              </nav>

              <div  className="search-design-furniture">
                <IoIosSearch className="search-design-furniture-icon"/>
                <input
                  type="text"
                  placeholder="Search here"
                  className="search-design-furniture-input"
                />
              </div>

              {itemsName === "model" && (
                <div className="furniture-categories">
                  {Array.from({ length: 12 }).map((_, index) => (
                    <p key={index}>Categories</p>
                  ))}
                </div>
              )}

              <section className="design-section">
                {itemsName === "design" &&
                  designImages.map((imgSrc, index) => (
                    <figure key={index} className="design-figure">
                      <img
                        src={imgSrc}
                        alt={`item ${index + 1}`}
                        className="design-image"
                      />
                      <figcaption className="design-caption">
                        <h2 className="design-title">Sample Name Here</h2>
                        <div className="designer-info">
                          <img
                            src={imageMap[profileImg] || A1}
                            alt="Designer Profile"
                            className="designer-profile-img"
                          />
                          <h3 className="designer-name">Designer Name</h3>
                        </div>
                      </figcaption>
                    </figure>
                  ))}
                  
                {itemsName === "model" &&
                  modelImages.map((imgSrc, index) => (
                    <figure key={index} className="design-figure">
                      <img
                        src={imgSrc}
                        alt={`item ${index + 1}`}
                        className="design-image"
                      />
                      <figcaption className="design-caption">
                        <h2 className="design-title">Sample Name Here</h2>
                        <div className="designer-info">
                          <img
                            src={imageMap[profileImg] || A1}
                            alt="Designer Profile"
                            className="designer-profile-img"
                          />
                          <h3 className="designer-name">Designer Name</h3>
                        </div>
                      </figcaption>
                    </figure>
                  ))}
              </section>
            </section>
          </>
        )}
      </div>
    </article>
  );
}

export default UnityLandingPage;
