import Quill from 'quill';

class CustomProjectNameBox {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;

    // Add handler to toolbar
    const toolbar = this.quill.getModule('toolbar');
    if (toolbar) {
      toolbar.addHandler('customProjectNameBox', this.showTextBox.bind(this));
    }
  }

  showTextBox() {
    const selection = this.quill.getSelection();
    if (selection) {
      const range = this.quill.getSelection(true);
      this.options.showTextBox(range);
    }
  }
}

Quill.register('modules/customProjectNameBox', CustomProjectNameBox);

export default CustomProjectNameBox;
