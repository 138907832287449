import React from 'react';
import './mobileScreenMessage.css'; // Import the CSS file

const MobileScreenMessage = () => {
  return (
    <div className="message-container">
      <p className="message-greeting">Hey There!</p>
      <p className="message-apology">We are sorry!</p>
      <p className="message-info">
        'Quotation making' is not compatible with mobile devices. Please use a laptop to make one.
      </p>
    </div>
  );
};

export default MobileScreenMessage;
