
import { SET_MOBILE_SCREEN } from "../actions/refreshToken.action";


const initialState = {
    isMobile: false, 
};

const isMobileScreenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOBILE_SCREEN:
      return {
        ...state,
        isMobile: action.payload,
      };
    default:
      return state;
  }
};

export default isMobileScreenReducer;
