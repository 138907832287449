import React, { useEffect, useState } from "react";
import { LuPen } from "react-icons/lu";
import "./profile_card.css";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AXIOS from "../../services/axios";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "../../services/redux/thunk/authThunk";
import A1 from "../../assets/profile_avatars/A1.png"
import A2 from "../../assets/profile_avatars/A2.png";
import A3 from "../../assets/profile_avatars/A3.png";
import A4 from "../../assets/profile_avatars/A4.png";
import A5 from "../../assets/profile_avatars/A5.png";
import A6 from "../../assets/profile_avatars/A6.png";

function Profile_card({ setDropDown, dropDown }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profile, setProfile] = useState("");
  const adminRole = useSelector((state) => state.auth.adminRole);
  const profileImg = useSelector((state) => state.auth.profileImg);

  const imageMap = {
    A1: A1,
    A2: A2,
    A3: A3,
    A4: A4,
    A5: A5,
    A6: A6,
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await AXIOS.get("/user/my-profile");
        if (response.status === 200) {
          setProfile(response.data.userInfo);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, []);

  const handleLogout = async () => {
    try {
      const response = await AXIOS.post("/user/logout", {
        withCredentials: true,
      });
      if (response.status === 200) {
        dispatch(refreshToken());
        setDropDown({
          ...dropDown,
          signUp: false,
          login: false,
          otp: false,
          profile: false,
        });
      } else {
        alert("Logout failed. Please try again.");
      }
    } catch (error) {
      alert("An error occurred during logout. Please try again later.");
    }
  };

  return (
    <section className="profile-card-section">
      <article className="profile-card-article">
        <IoMdClose
          className="signup-close-icon profile-card-close-icon"
          onClick={() => {
            setDropDown({
              ...dropDown,
              signUp: false,
              login: false,
              profile: false,
            });
          }}
        />
        <section className="profile-card-info">
          <figure className="profile-card-image">
            <img
              src={imageMap[profileImg] || A1}
              alt="profile image"
            />
            <LuPen className="profile-card-edit-icon" />
          </figure>
          <h1 className="profile-card-name">{profile.name}</h1>
          <p className="profile-card-email">{profile.email}</p>
        </section>
        <section className="profile-card-menu">
          <section className="profile-card-menu-item">
            <h2
              onClick={() => {
                setDropDown({
                  ...dropDown,
                  signUp: false,
                  login: false,
                  profile: false,
                });
                navigate("/my-profile");
              }}
            >
              My Profile
            </h2>
          </section>
          {adminRole === "Designer" && (
            <section className="profile-card-menu-item">
              <h2
                onClick={() => {
                  setDropDown({
                    ...dropDown,
                    signUp: false,
                    login: false,
                    profile: false,
                  });
                  navigate("/pricing");
                }}
              >
                Cadet Membership
              </h2>
            </section>
          )}
          {adminRole === "Designer" && (
            <section className="profile-card-menu-item">
              <h2
                onClick={() => {
                  setDropDown({
                    ...dropDown,
                    signUp: false,
                    login: false,
                    profile: false,
                  });
                  navigate("/quotation-template");
                }}
              >
                My Template
              </h2>
            </section>
          )}
          <section className="profile-card-menu-item">
            <h2 onClick={handleLogout}>Logout</h2>
          </section>
        </section>
      </article>
    </section>
  );
}

export default Profile_card;
